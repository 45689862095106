import {
  FETCH_CURRENT_SEM_UNITS_REQUEST,
  FETCH_CURRENT_SEM_UNITS_FAILURE,
  FETCH_CURRENT_SEM_UNITS_SUCCESS,
} from "./semUnitsTypes";

import axios from "axios";
import Axios from "axios";
import { basePath } from "../../util/basePath";

const url = basePath.url;

export const fetchCurrentSemUnitsRequest = () => {
  return {
    type: FETCH_CURRENT_SEM_UNITS_REQUEST,
  };
};

export const fetchCurrentSemUnitsFailure = (error) => {
  return {
    type: FETCH_CURRENT_SEM_UNITS_FAILURE,
    payload: error,
  };
};

export const fetchCurrentSemUnitsSuccess = (units) => {
  return {
    type: FETCH_CURRENT_SEM_UNITS_SUCCESS,
    payload: units,
  };
};

export const fetchCurrentSemUnits = (admission_no, period_id) => {
  Axios.defaults.withCredentials = true;

  return function (dispatch) {
    dispatch(fetchCurrentSemUnitsRequest());

    axios
      .get(
        `${url}sem-registration/registered-units?admission_no=${admission_no}&period_id=${period_id}`
      )
      .then((response) => {
        const units = response.data.data;
        // console.log({ units });
        dispatch(fetchCurrentSemUnitsSuccess(units));
      })
      .catch((err) => {
        dispatch(fetchCurrentSemUnitsFailure(err.message));
      });
  };
};
