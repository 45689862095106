import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../redux/logger/loggerActions";
import { Button, Typography } from "@mui/material";
import Axios from "axios";
import { basePath } from "../util/basePath";
import ProfileImageName from "./ProfileImageName.js";
import { useNavigate } from "react-router-dom";

import {
  Logout,
  LogoutOutlined,
  LogoutRounded,
  LogoutTwoTone,
  Menu,
  ExpandMoreOutlined,
  ExpandLessOutlined,
  HomeOutlined,
  HomeRounded,
} from "@mui/icons-material";
import { useState } from "react";
import DropDownProfileBox from "./DropDownProfileBox.js";
const NavBar = ({ showMenuBtn, handleShowMenuButton }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logger = useSelector((state) => {
    return state.logger;
  });

  let userImage = logger.user
    ? `${basePath.userImageUrl}${logger.user.avatar}`
    : null;

  const handleLogout = async () => {
    try {
      const signout = await Axios.get(`${basePath.url}logout`);
      dispatch(logout());
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        style={{ position: "relative", padding: 0, margin: 0, width: "100%" }}
      >
        <ul
          className="nav"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <li className="toggleMenu" onClick={handleShowMenuButton}>
            <Menu
              style={{
                fontWeight: 600,
                fontSize: 35,
                color: "white",
                boxSizing: "border-box",
              }}
            />
          </li>
          {/* <li>
            <Button
              style={{ color: "white", fontWeight: 600 }}
              size="medium"
              onClick={handleLogout}
              endIcon={<LogoutRounded style={{ fontWeight: 600 }} />}
            >
              Logout
            </Button>
          </li> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flex: 1,
              padding: 0,
              paddingLeft: 20,
              margin: 0,
              height: "50px",
              boxSizing: "border-box",
              // backgroundColor: "skyblue",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 25,
                cursor: "pointer",
                paddingLeft: 20,
                margin: 0,
                height: "50px",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: 600,
                  color: "#fff",
                  fontSize: 14,
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                HOME
              </Typography>
              {/* 
              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: 600,
                  color: "#fff",
                  fontSize: 14,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/lec-evaluation");
                }}
              >
                LECTURER EVALUATION
              </Typography>

              <Typography
                variant="subtitle2"
                style={{
                  fontWeight: 600,
                  color: "#fff",
                  fontSize: 14,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/exam-card");
                }}
              >
                EXAM CARD
              </Typography> */}
            </div>
            {/* <HomeRounded
              style={{ color: "#272626", fontSize: 35, cursor: "pointer" }}
              onClick={() => {
                navigate("/");
              }}
            /> */}
          </div>
          <li
            className="navItem"
            style={{ float: "right", cursor: "pointer" }}
            onClick={() => {
              return setShowSubMenu((val) => {
                return !val;
              });
            }}
          >
            {/* <Button
          style={{ color: "white", fontWeight: 600 }}
          size="medium"
          onClick={handleLogout}
          endIcon={<LogoutRounded  style={{ fontWeight: 600 }} />}
        >
          Logout
        </Button> */}
            <ProfileImageName />
            {showSubMenu ? (
              <ExpandLessOutlined
                style={{ cursor: "pointer", marginLeft: 5, marginRight: 5 }}
              />
            ) : (
              <ExpandMoreOutlined
                style={{ cursor: "pointer", marginLeft: 5, marginRight: 5 }}
              />
            )}
          </li>
        </ul>
        <div
          className="sub-menu-wrap"
          style={{ maxHeight: showSubMenu ? 400 : 0 }}
        >
          <div className="sub-menu">
            <DropDownProfileBox />
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
