import React, { Component, useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Document,
  View,
  Text,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useQuery } from "react-query";

import { useSelector } from "react-redux";
import axios from "axios";
import Axios from "axios";
import { Typography, Button, CircularProgress, Container } from "@mui/material";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import {
  ErrorOutlined,
  ArrowForward,
  Edit,
  Lock,
  LockOpen,
  PrintOutlined,
  DownloadOutlined,
} from "@mui/icons-material";

import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ProcessProgress from "../../components/ProcessProgress";
import styles from "../../styles/sidenav.module.css";
import { basePath } from "../../util/basePath";
import UnitsList from "../../components/courseUnits/UnitsList";
import ExamCardPdf from "./ExamCardPdf";
import logo from "../../assets/icon.png";

// const fetchUnits = (period_id, admission_no) => {
//   return axios.get(
//     `${basePath.url}sem-registration/registered-units?admission_no=${admission_no}&period_id=${period_id}`
//   );
// };

// const fetchFeesStatus = (period_id, admission_no) => {
//   return axios.get(
//     `${basePath.url}fees/fees-balance?admission_no=${admission_no}&period_id=${period_id}`
//   );
// };

const ExamCard = () => {
  Axios.defaults.withCredentials = true;
  const [isLoadingUnits, setIsloadingUnits] = useState(false);
  const [isErrorUnits, setIsErrorUnits] = useState(null);
  const [registeredUnits, setRegisteredUnits] = useState(null);

  const [isLoadingFees, setIsloadingFees] = useState(false);
  const [isErrorFees, setIsErrorFees] = useState(null);
  const [feeStatus, setFeeStatus] = useState(null);

  const [feeIssue, setFeeIssue] = useState(false);
  const [registrationIssue, setRegistrationIssue] = useState(false);

  const [openCard, setOpenCard] = useState(false);
  const [studentSession, setStudentSession] = useState(null);

  const [examCardSerial, setExamCardSerial] = useState(null);

  const [openProgress, setOpenProgress] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const [progressTitle, setProgressTitle] = useState("");

  const componentRef = useRef(null);

  const semUnitsRegistered = useSelector((state) => {
    return state.semUnitsReducer.registeredUnits;
  });
  const semPeriod = useSelector((state) => {
    return state.semPeriodReducer;
  });
  const semPeriodId = useSelector((state) => {
    return state.semPeriodReducer?.currentSemPeriod?.periodID;
  });
  const admission_no = useSelector((state) => {
    return state.logger?.user?.admissionNo;
  });

  const studentName = useSelector((state) => {
    return state.logger?.user?.names;
  });

  const studentCourse = useSelector((state) => {
    return state.logger?.user?.courseName;
  });

  const logger = useSelector((state) => {
    return state.logger;
  });

  const avatar = useSelector((state) => {
    return logger.user.avatar;
  });
  let userImage = logger.user
    ? `${basePath.userImageUrl}${logger.user.avatar}`
    : null;

  // const admission_no = useSelector((state) => {
  //   return state.logger?.user?.admissionNo;
  // });

  const loadDetails = async () => {
    try {
      setIsloadingUnits(true);
      setIsErrorUnits(null);
      setRegisteredUnits(null);
      const units = await axios.get(
        `${basePath.url}sem-registration/registered-units?admission_no=${admission_no}&period_id=${semPeriodId}`
      );

      setIsloadingUnits(false);
      setIsErrorUnits(null);

      setRegisteredUnits(units.data.data);

      setStudentSession(units?.data.data[0]?.sessionName);

      setExamCardSerial(units?.data.data[0]?.examSerial);

      //load fee status
      setIsloadingFees(true);
      setIsErrorFees(null);
      setFeeStatus(null);
      const fee_status = await axios.get(
        `${basePath.url}fees/fees-balance?admission_no=${admission_no}&period_id=${semPeriodId}`
      );

      let fee_details = fee_status.data.data;

      //set fee issue
      if (
        fee_details.fee_balance > 0 &&
        fee_details.examExemptionStatus == false
      ) {
        setFeeIssue(true);
      }

      //set registration issue
      if (units.data.data.length < 1) {
        setRegistrationIssue(true);
      }

      for (let i = 0; i < units.data.data.length; i++) {
        let currentUnit = units.data.data[i];
        if (currentUnit.isEvaluated == false) {
          setRegistrationIssue(true);
          break;
        }
      }

      setIsloadingFees(false);
      setIsErrorFees(null);
      setFeeStatus(fee_status.data.data);

      // console.log(units);
      // console.log(fee_status);
    } catch (error) {
      setIsErrorUnits(error.message);
    }
  };

  const getSerialNo = async () => {
    setProgressTitle("Exam Card");
    setProgressMessage(`Fetching Exam Card Serial....`);
    setOpenProgress(true);

    try {
      const setCardSerial = await axios.put(
        `${basePath.url}exams/exam-card-serial?admission_no=${admission_no}&period_id=${semPeriodId}`
      );

      setExamCardSerial(setCardSerial.data.data.exam_serial);

      setProgressTitle("");
      setProgressMessage(``);
      setOpenProgress(false);

      // setOpenCard(true);
    } catch (error) {
      setProgressTitle("Exam Card");
      setProgressMessage(`Error Fetching Exam Card Serial! Please try again`);
      setTimeout(() => {
        setOpenProgress(false);
      }, 3000);
    }
  };

  const loadFees = async () => {
    let issues_count = 0;
    try {
      //load fee status
      setIsloadingFees(true);
      setIsErrorFees(null);
      setFeeStatus(null);
      const fee_status = await axios.get(
        `${basePath.url}fees/fees-balance?admission_no=${admission_no}&period_id=${semPeriodId}`
      );

      let fee_details = fee_status.data.data;

      //set fee issue
      if (
        fee_details.fee_balance > 0 &&
        fee_details.examExemptionStatus == false
      ) {
        setFeeIssue(true);
        issues_count += 1;
      }

      //set registration issue
      if (semUnitsRegistered.length < 1) {
        setRegistrationIssue(true);
        issues_count += 1;
      }

      for (let i = 0; i < semUnitsRegistered.length; i++) {
        let currentUnit = semUnitsRegistered[i];
        if (currentUnit.isEvaluated == false) {
          setRegistrationIssue(true);
          issues_count += 1;
          break;
        }
      }

      setIsloadingFees(false);
      setIsErrorFees(null);
      setFeeStatus(fee_status.data.data);
      if (issues_count == 0) {
        const serial_no = await getSerialNo();
      }
    } catch (error) {
      setIsErrorUnits(error.message);
    }
  };

  useEffect(() => {
    if (semUnitsRegistered.length < 1) {
      loadDetails();
    } else {
      setRegisteredUnits(semUnitsRegistered);

      setStudentSession(semUnitsRegistered[0]?.sessionName);

      setExamCardSerial(semUnitsRegistered[0]?.examSerial);
      loadFees();
    }
  }, []);

  const handleOpenCard = async () => {
    if (examCardSerial) {
      setOpenCard(true);
      return;
    }
    setProgressTitle("Exam Card");
    setProgressMessage(`Fetching Exam Card Serial....`);
    setOpenProgress(true);

    try {
      const setCardSerial = await axios.put(
        `${basePath.url}exams/exam-card-serial?admission_no=${admission_no}&period_id=${semPeriodId}`
      );

      setExamCardSerial(setCardSerial.data.data.exam_serial);

      setProgressTitle("");
      setProgressMessage(``);
      setOpenProgress(false);

      setOpenCard(true);
    } catch (error) {
      setProgressTitle("Exam Card");
      setProgressMessage(`Error Fetching Exam Card Serial! Please try again`);
      setTimeout(() => {
        setOpenProgress(false);
      }, 3000);

      return;
    }
  };

  // const handleDownloadPdf = async () => {
  //   const element = componentRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/jpeg");

  //   const pdf = new jsPDF();
  //   const imgProperties = pdf.getImageProperties(data);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  //   pdf.addImage(data, "jpeg", 0, 0, pdfWidth, pdfHeight);
  //   pdf.save(`${admission_no}-exam_card`);
  // };
  // const {
  //   isLoading: isLoadingUnits,
  //   data: dataUnits,
  //   isError: isErrorUnits,
  //   error: errorUnits,
  //   isFetching: isFecthingUnits,
  //   // refetch: refetchUnits,
  // } = useQuery("registered_units", fetchUnits(semPeriodId, admission_no), {
  //   staleTime: 30000,
  // });
  // const {
  //   isLoading: isLoadingFees,
  //   data: dataFees,
  //   isError: isErrorFees,
  //   error: errorFees,
  //   isFetching: isFecthingFees,
  //   // refetch: refetchFees,
  // } = useQuery("fee_status", fetchFeesStatus(semPeriodId, admission_no), {
  //   staleTime: 30000,
  // });

  const handlePrint = useReactToPrint({
    // content: () => componentRef.current,
    contentRef: componentRef,
    documentTitle: `Exam Card-${admission_no}`,
    onAfterPrint: () => setOpenCard(false),
  });
  return (
    <>
      <ProcessProgress
        openProgress={openProgress}
        setOpenProgress={setOpenProgress}
        progressMessage={progressMessage}
        progressTitle={progressTitle}
      />

      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openCard}
        onClose={() => {
          setOpenCard((state) => {
            return !state;
          });
        }}

        // PaperProps={{ sx: { height: "100%" } }}
      >
        {/* <DialogTitle
          borderBottom={1}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2">Project / Proposal</Typography>
          <Close
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenViewInternship((state) => {
                return !state;
              });
            }}
          />{" "}
        </DialogTitle> */}
        <DialogContent
          style={{
            margin: "10px",
            backgroundColor: "white",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <Button
            variant="outlined"
            style={{ color: "green", borderColor: "green" }}
            type="button"
            // onClick={handleDownloadPdf}
            onClick={handlePrint}
            endIcon={<DownloadOutlined />}
          >
            Download / Print
          </Button>

          <PDFViewer style={{ width: "100%", height: 600 }}>
            <ExamCardPdf
              examCardSerial={examCardSerial}
              registeredUnits={registeredUnits}
              studentSession={studentSession}
              semPeriod={semPeriod}
              admission_no={admission_no}
              studentName={studentName}
              studentCourse={studentCourse}
              avatar={avatar}
              userImage={userImage}
            />
          </PDFViewer>

          {/* <PDFViewer>
            <ExamCardPdf
              examCardSerial={examCardSerial}
              registeredUnits={registeredUnits}
              studentSession={studentSession}
            />
          </PDFViewer> */}
          {/* <div
            style={{ width: "100%", padding: 50, boxSizing: "border-box" }}
            ref={componentRef}
          >
            
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ padding: 10 }}>
                <div
                  style={{
                    border: "1px solid #ccc",
                    width: 150,
                    height: 150,
                    boxSizing: "border-box",
                    padding: 1,
                  }}
                >
                  {logger.user.avatar ? (
                    <img
                      // className="user-img"
                      src={userImage}
                      alt="Profile Image"
                      style={{ width: 150, height: "auto" }}
                    />
                  ) : (
                    <Typography>No Passport Image</Typography>
                  )}
                </div>
              </div>
              <Typography variant="h6">
                {" "}
                {examCardSerial && examCardSerial}
              </Typography>
            </div>

            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  dispay: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "black",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    dispay: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: "120px", height: "auto" }}
                    />
                  </div>
                </div>
                <div>
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: 25,
                      textAlign: "center",
                      fontWeight: 600,
                    }}
                  >
                    The East African University
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: 24,
                      textAlign: "center",
                      fontWeight: 600,
                    }}
                  >
                    Examination Card
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 24, textAlign: "center" }}
                  >
                    {semPeriod?.currentSemPeriod?.periodName}
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  dispay: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "black",
                  width: "100%",
                  marginTop: 30,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    dispay: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 18, fontWeight: 500 }}
                  >
                    Name: &nbsp;{" "}
                    <span style={{ fontWeight: 600 }}>{studentName}</span>
                  </Typography>
                </div>
                <div
                  style={{
                    width: "100%",
                    dispay: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 18, fontWeight: 500 }}
                  >
                    Registration Number:&nbsp;{" "}
                    <span style={{ fontWeight: 600 }}>{admission_no}</span>
                  </Typography>
                </div>
                <div
                  style={{
                    width: "100%",
                    dispay: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 18, fontWeight: 500 }}
                  >
                    Course:&nbsp;{" "}
                    <span style={{ fontWeight: 600 }}>{studentCourse}</span>
                  </Typography>
                </div>
                <div
                  style={{
                    width: "100%",
                    dispay: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 18, fontWeight: 500 }}
                  >
                    Year of Study:&nbsp;{" "}
                    <span
                      style={{ fontWeight: 600, textTransform: "uppercase" }}
                    >
                      {studentSession}
                    </span>
                  </Typography>
                </div>
                <div
                  style={{
                    width: "100%",
                    dispay: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 18, fontWeight: 500 }}
                  >
                    Semester Period:&nbsp;{" "}
                    <span style={{ fontWeight: 600 }}>
                      {semPeriod?.currentSemPeriod?.periodName}
                    </span>
                  </Typography>
                </div>
              </div>

              <div
                style={{
                  dispay: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  color: "black",
                  width: "100%",
                  marginTop: 30,
                }}
              >
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 30,
                  }}
                >
                  Note
                </div>

                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  1. Bring this card with you whenever you are coming for your
                  examination
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 16, fontWeight: 500 }}
                ></Typography>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  {" "}
                  2. Ensure you sign the invigilator's form for the subject done
                  in each session
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  3. Submit this card to Academic Registrar's office immediately
                  after the examination period is over
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  4. This card is confidential and it must be produced to the
                  invigilator when required during each examination
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 16, fontWeight: 500 }}
                >
                  5. The name by which you are officially registered at the
                  university is the name that will appear on the
                  degree/diploma/certificate. Any error must be communicated to
                  the registrar before the end of the examination.(Applicable to
                  final year students only)
                </Typography>
              </div>

              <div style={{ margin: 20 }}></div>

              {registeredUnits && registeredUnits.length > 0 ? (
                <div style={{ width: "100%", boxSizing: "border-box" }}>
                  <table
                    id={styles.plain_table}
                    border={1}
                    style={{
                      marginTop: 60,
                      marginBottom: 60,

                      // padding: 5,
                    }}
                  >
                    <thead>
                      <tr style={{ textAlign: "left" }}>
                        <th
                          style={{
                            fontWeight: 600,
                            fontSize: 14,
                            color: "black",
                          }}
                        >
                          #
                        </th>
                        <th>
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: 600,
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            UNIT CODE
                          </Typography>
                        </th>
                        <th>
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: 600,
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            UNIT TITLE
                          </Typography>
                        </th>
                        <th>
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: 600,
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            LECTURER
                          </Typography>
                        </th>

                        <th>
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: 600,
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            SIGN
                          </Typography>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {registeredUnits &&
                        registeredUnits.map((internship, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ textAlign: "center" }}>
                                {" "}
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 12,
                                    color: "black",
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                              </td>
                              <td style={{ width: "10%" }}>
                                {" "}
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 12,
                                    color: "black",
                                  }}
                                >
                                  {internship.unitCode}
                                </Typography>
                              </td>
                              <td style={{ width: "40%" }}>
                                {" "}
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 12,
                                    color: "black",
                                  }}
                                >
                                  {internship.unitName}
                                </Typography>
                              </td>

                              <td style={{ width: "30%" }}>
                                {" "}
                                <Typography
                                  variant="subtitle2"
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 12,
                                    color: "black",
                                    // textTransform: "capitalize",
                                  }}
                                >
                                  {internship?.names
                                    ? internship.names.toUpperCase()
                                    : "N/A"}
                                </Typography>
                              </td>

                              <td style={{ width: "15%" }}>
                                <Typography
                                  variant="subtitle2"
                                  style={{ fontWeight: 400, fontSize: 12 }}
                                ></Typography>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div> */}
        </DialogContent>
      </Dialog>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div
          style={{
            display: "flex",

            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div className={styles.topContent}>
            <Typography
              variant="h6"
              style={{ fontWeight: 400, color: "#282727", paddingLeft: 10 }}
            >
              Exam Card
            </Typography>
          </div>
          <div className="plainContent">
            <Typography
              variant="subtitle2"
              style={{ fontSize: "16px", color: "#3c3d3c" }}
            >
              Current Semester Period:{" "}
              <div style={{ color: "#507b50", display: "inline-block" }}>
                {semPeriod?.currentSemPeriod?.periodName}
              </div>
            </Typography>
          </div>
        </div>
        {/* <div>{examCardSerial}</div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* {feeIssue == true || registrationIssue == true ? (
            <Button variant="outline" startIcon={PrintOutlined}>
              Print Exam Card
            </Button>
          ) : null} */}
          {registeredUnits &&
          feeIssue == false &&
          registrationIssue == false &&
          examCardSerial ? (
            <>
              {" "}
              {/* <Button
                variant="outlined"
                size={"medium"}
                endIcon={<PrintOutlined />}
                onClick={() => {
                  handleOpenCard();
                }}
                style={{
                  borderColor: "green",
                  color: "green",
                }}
              >
                Print Exam Card
              </Button> */}
              <PDFDownloadLink
                fileName="exam card"
                document={
                  <ExamCardPdf
                    examCardSerial={examCardSerial}
                    registeredUnits={registeredUnits}
                    studentSession={studentSession}
                    semPeriod={semPeriod}
                    admission_no={admission_no}
                    studentName={studentName}
                    studentCourse={studentCourse}
                    logger={logger}
                    userImage={userImage}
                  />
                }
              >
                <Button
                  endIcon={<DownloadOutlined />}
                  variant="outlined"
                  style={{
                    borderColor: "green",
                    color: "green",
                    disabled: examCardSerial ? false : true,
                  }}
                >
                  Download PDF
                </Button>
              </PDFDownloadLink>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flex: 1,
                gap: 3,
                marginRight: 10,
              }}
            >
              <RemoveCircleOutlineOutlinedIcon style={{ color: "crimson" }} />
              <Typography variant="subtitle2">
                Print Exam Card Prohibited
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div
        style={{ width: "100%", border: "1px solid #ccc", marginTop: 10 }}
      ></div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {feeIssue == true ? (
          <div
            className="notificationWrapper"
            style={{ marginBottom: 10, width: "100%" }}
          >
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              Please Clear Fees to Download Exam Card
            </Typography>
          </div>
        ) : null}
        {registrationIssue == true ? (
          <div
            className="notificationWrapper"
            style={{ width: "100%", marginBottom: 10 }}
          >
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              Please Complete Lecturer Evaluation to Proceed
            </Typography>
          </div>
        ) : null}

        {isLoadingUnits ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              Loading....
            </Typography>
          </div>
        ) : null}
        {isLoadingFees ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              confirming details....
            </Typography>
          </div>
        ) : null}
        {isErrorUnits ? <div>{isErrorUnits}</div> : null}

        {/* {dataUnits
          ? console.log(dataUnits)
          : 
          <UnitsList
              units={registeredUnits}
              handleSelectedUnit={handleSelectedUnit}
            />

            } */}

        {registeredUnits && registeredUnits.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "20px",
              width: "100%",
            }}
          >
            <UnitsList
              units={registeredUnits}
              // handleSelectedUnit={handleSelectedUnit}
            />
          </div>
        ) : // <Typography variant="subtitle2" style={{ fontSize: 14 }}>
        //   No Units Registered!
        // </Typography>
        null}
      </div>
    </>
  );
};

export default ExamCard;
