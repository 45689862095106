import { useEffect, useState } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { useLocation, Route, Routes, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "./redux/logger/loggerActions";
import { fetchCurrentSemUnits } from "./redux/semUnits/semUnitsActions";
import { fetchSemPeriod } from "./redux/semPeriod/semPeriodActions";
import { navData } from "./util/navLinks";

import {
  AccountCircle,
  EditNote,
  Smartphone,
  AlternateEmail,
  Email,
  Phone,
  EmailOutlined,
  HomeOutlined,
  FactoryRounded,
  HomeRounded,
  AccountTreeOutlined,
  AccountTreeRounded,
  QuizOutlined,
  LogoutRounded,
  LogoutOutlined,
  RecentActorsOutlined,
  AnalyticsOutlined,
  AnalyticsRounded,
} from "@mui/icons-material";
import { Spinner } from "react-activity";
import Axios from "axios";
import axios from "axios";
import { basePath } from "./util/basePath";
import logo from "./assets/icon.png";
import LoginPage from "./components/login/LoginPage";
import NavBar from "./components/navbar";
import RegisteredUnits from "./pages/RegisteredUnits";
import InternshipList from "./pages/internship/InternshipList";
import InternshipAdd from "./pages/internship/InternshipAdd";
import InternshipEdit from "./pages/internship/InternshipEdit";
import ProjectAdd from "./pages/project/ProjectAdd";
import ProjectEdit from "./pages/project/ProjectEdit";
import ProjectList from "./pages/project/ProjectList";
import ExamCard from "./pages/exam/ExamCard";
import LecturerEvaluation from "./pages/lecEvaluation/LecturerEvaluation";
import Home from "./pages/home/";
import ExamList from "./pages/ExamList";
import CourseUnits from "./components/lec_evaluation/course_units";
import { Typography, Button } from "@mui/material";
function App() {
  const [showMenuBtn, setShowMenuBtn] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const [currentSelectedUnit, setCurrentSelectedUnit] = useState(null);
  const [openCard, setOpenCard] = useState(false);

  const queryClient = new QueryClient();
  let location = useLocation();

  // useEffect(() => {
  //   setCurrentLocation(currentLocation);
  //   console.log(location);
  // }, [location]);
  const handleShowMenuButton = () => {
    setShowMenuBtn((val) => {
      return !val;
    });
  };
  const isLogged = useSelector((state) => {
    return state.logger.isLogged;
  });

  const url = basePath.url;

  const logger = useSelector((state) => {
    return state.logger;
  });
  const admission_no = useSelector((state) => {
    return state.logger?.user?.admissionNo;
  });

  const semUnits = useSelector((state) => {
    return state.semUnitsReducer;
  });
  const semUnitsRegistered = useSelector((state) => {
    return state.semUnitsReducer.registeredUnits;
  });

  const semPeriod = useSelector((state) => {
    return state.semPeriodReducer;
  });
  const semPeriodId = useSelector((state) => {
    return state.semPeriodReducer?.currentSemPeriod?.periodID;
  });

  let userImage = logger.user
    ? `${basePath.userImageUrl}${logger.user.avatar}`
    : null;

  const dispatch = useDispatch();

  Axios.defaults.withCredentials = true;

  const handleLogout = async () => {
    try {
      const signout = await Axios.get(`${basePath.url}logout`);
      dispatch(logout());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    Axios.get(`${url}login_get`)
      .then((result) => {
        // console.log(result.data);
        dispatch(fetchSemPeriod());

        dispatch(login(result.data.user));
        // console.log("niaje");
        // console.log(logger);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  useEffect(() => {
    if (logger?.user?.admissionNo && semPeriod?.currentSemPeriod) {
      dispatch(fetchCurrentSemUnits(logger?.user?.admissionNo, semPeriodId));
    }
  }, [logger, semPeriod]);
  // useEffect(() => {
  //   dispatch(fetchCurrentSemUnits(logger?.user?.admissionNo, semPeriodId));
  // }, [logger]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        {isLogged ? (
          <>
            <NavBar
              showMenuBtn={showMenuBtn}
              handleShowMenuButton={handleShowMenuButton}
            />
            <div
              style={{
                marginTop: 0,
                marginBottom: 10,
                fontWeight: "normal",
                fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                fontSize: 16,
                lineHeight: 1.6,
                wordSpacing: "normal",
                borderBottom: "1px solid #ccc",
                padding: 5,
                // backgroundColor: "#fff",
              }}
            >
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,

                  padding: 2,
                  margin: "auto",
                  width: "90%",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    // marginTop: 10,
                    // marginBottom: 10,

                    // padding: 2,
                    // margin: "auto",
                    // width: "90%",y
                    // boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img src={logo} height={20} width={20} />
                  </div>
                  <div style={{ paddingBottom: 5 }}>
                    &nbsp; The East African University
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              {location.pathname == "/lec-evaluation" ? (
                <>
                  <div className="column side">
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: 18,
                        fontWeight: 500,
                        borderBottom: "1px solid #acacac",
                      }}
                    >
                      Course Units Registered
                    </Typography>
                    <div style={{ marginTop: 20 }}></div>
                    {semUnits?.loading && (
                      <div
                        style={{
                          marginTop: "20%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner size={25} color="#04aa6d" />{" "}
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#0a8fc4" }}
                        >
                          fetching Units
                        </Typography>
                      </div>
                    )}
                    {semUnitsRegistered && semUnitsRegistered.length > 0
                      ? // <ul>
                        // {
                        semUnitsRegistered.map((course_unit) => {
                          let evaluation_status = "";

                          return (
                            <CourseUnits
                              key={course_unit.unitID}
                              course_unit={course_unit}
                              currentSelectedUnit={currentSelectedUnit}
                              setCurrentSelectedUnit={setCurrentSelectedUnit}
                              openCard={openCard}
                              setOpenCard={setOpenCard}
                            />
                          );
                        })
                      : // }
                        // </ul>
                        !semUnits?.loading && (
                          <Typography variant="subtitle2">
                            No Units Registered for the current period
                          </Typography>
                        )}
                  </div>
                </>
              ) : (
                <div className="column side ">
                  <ul>
                    <li>
                      <Link
                        to="/"
                        onClick={() => {
                          setCurrentLocation("home");
                        }}
                      >
                        <HomeRounded
                          className="nav-icon"
                          style={{ fontSize: 30 }}
                        />
                        <span
                          className="nav-item"
                          // style={{
                          //   backgroundColor: "skyblue",

                          //   height: 40,
                          //   display: "flex",
                          //   justifyContent: "center",
                          //   alignItems: "center",
                          // }}
                        >
                          Home
                        </span>
                      </Link>
                      {/* <span className="tool-tip">Home</span> */}
                    </li>
                    <li>
                      <Link
                        to="/lec-evaluation"
                        onClick={() => setCurrentSelectedUnit(null)}
                      >
                        <AnalyticsRounded
                          className="nav-icon"
                          style={{ fontSize: 30 }}
                        />
                        <span className="nav-item"> Lecturer Evaluation</span>
                      </Link>
                      {/* <span className="tool-tip">Home</span> */}
                    </li>
                    <li>
                      <Link to="/exam-card">
                        <RecentActorsOutlined
                          className="nav-icon"
                          style={{ fontSize: 30 }}
                        />
                        <span className="nav-item"> Exam Card</span>
                      </Link>
                      {/* <span className="tool-tip">Home</span> */}
                    </li>
                    <li>
                      <Link to="/project">
                        <AccountTreeOutlined
                          className="nav-icon"
                          style={{ fontSize: 30 }}
                        />
                        <span className="nav-item"> Project</span>
                      </Link>
                      {/* <span className="tool-tip">Home</span> */}
                    </li>

                    <li>
                      <Link to="/internship">
                        <FactoryRounded
                          className="nav-icon"
                          style={{ fontSize: 30 }}
                        />
                        <span className="nav-item"> Internship</span>
                      </Link>
                      {/* <span className="tool-tip">Home</span> */}
                    </li>

                    {/* <li>
                    <Link to="/exams">
                      <QuizOutlined
                        className="nav-icon"
                        style={{ fontSize: 30 }}
                      />
                      <span className="nav-item"> Online Exams</span>
                    </Link>
                  
                  </li> */}
                  </ul>

                  <ul
                    style={{
                      position: "absolute",
                      bottom: 50,
                      width: "100%",
                    }}
                  >
                    <li onClick={handleLogout}>
                      <Link to="#" style={{ width: "100%" }}>
                        <LogoutOutlined
                          style={{ fontSize: 30 }}
                          className="nav-icon"
                        />
                        <span className="nav-item">logout</span>
                      </Link>
                    </li>

                    {/* <li>
          <a href="#">Home</a>
        </li>
        <li className="active" style={{ float: "right" }}>
          <a href="#">Home</a>
        </li> */}
                  </ul>
                </div>
              )}

              <div className="column middle body_text">
                <Routes>
                  <Route path="/" element={<RegisteredUnits />} />
                  <Route
                    path="/lec-evaluation"
                    element={
                      <LecturerEvaluation
                        currentSelectedUnit={currentSelectedUnit}
                        openCard={openCard}
                        setOpenCard={setOpenCard}
                      />
                    }
                  />
                  <Route path="/exam-card" element={<ExamCard />} />
                  <Route path="/project" element={<ProjectList />} />
                  <Route path="/internship" element={<InternshipList />} />
                  <Route path="/internship-add" element={<InternshipAdd />} />
                  <Route path="/internship-edit" element={<InternshipEdit />} />
                  <Route path="/project-add" element={<ProjectAdd />} />
                  <Route path="/project-edit" element={<ProjectEdit />} />
                  <Route path="/exams" element={<RegisteredUnits />} />
                  {/* <Route path="/courses" element={<Courses />} />
                  <Route
                    path="/courses/course-preview/:id"
                    element={<CoursePreview />}
                  />
                  <Route path="/courses/my_courses" element={<MyCourses />} />
                  <Route
                    path="/courses/course-enrollment/:id"
                    element={<Enrollment />}
                  /> */}
                </Routes>
              </div>
              <div className="column right body_text">
                <div className="user-header" style={{ marginBottom: 20 }}>
                  {logger.user.avatar ? (
                    <img
                      className="user-img"
                      src={userImage}
                      alt="Profile Image"
                    />
                  ) : (
                    <AccountCircle style={{ fontSize: 40 }} color="action" />
                  )}

                  <div
                    style={{
                      flex: 1,
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: 12,
                        marginLeft: 10,
                        fontWeight: 600,
                      }}
                    >
                      {logger.user.otherNames}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontSize: 12,
                        marginLeft: 10,
                        fontWeight: 600,
                      }}
                    >
                      {logger.user.surname}
                    </Typography>
                  </div>
                </div>
                <div className="user-details">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      height: 50,
                    }}
                  >
                    <EditNote className="grey-icons" />
                  </div>
                  <div style={{ marginLeft: 10, height: 50 }}>
                    <Typography variant="subtitle2">
                      {logger.user.admissionNo}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: 11, color: "#757474" }}
                    >
                      Admission Number
                    </Typography>
                  </div>
                </div>
                <div className="user-details">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      height: 50,
                    }}
                  >
                    <Email className="grey-icons" />
                  </div>
                  <div style={{ marginLeft: 10, height: 50 }}>
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#074192" }}
                    >
                      {logger.user.email}
                      <br />
                      <span style={{ fontSize: 11, color: "#757474" }}>
                        Email
                      </span>
                    </Typography>
                  </div>
                </div>
                <div className="user-details">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      height: 50,
                    }}
                  >
                    <Phone className="grey-icons" />
                  </div>
                  <div
                    style={{
                      marginLeft: 10,

                      height: 50,
                    }}
                  >
                    <Typography variant="subtitle2" size={25}>
                      {logger.user.mobileNo} <br />{" "}
                      <span style={{ fontSize: 11, color: "#757474" }}>
                        Mobile
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer">
              <div style={{ textAlign: "center" }}>
                <p>&copy; {new Date().getFullYear()} Grahope Solutions</p>
              </div>
            </div>
          </>
        ) : (
          <LoginPage />
        )}
      </QueryClientProvider>
    </>
  );
}

export default App;
