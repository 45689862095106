import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  ErrorOutlined,
  CloseOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

const ProcessProgress = ({
  openProgress,
  setOpenProgress,
  progressMessage,
  progressTitle,
  progressError,
  progressComplete,
  handleCallBack,
}) => {
  return (
    <>
      <Dialog
        open={openProgress}
        // onClose={() => setOpenProgress(false)}
        // sx={{
        //   "& .MuiDialog-container": {
        //     justifyContent: "center",
        //     alignItems: "flex-start",
        //   },
        // }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #ccc",
            padding: "8px",
          }}
          // disableTypography
        >
          <div
            variant="subtitle2"
            style={{
              lineHeight: 1.6,
              fontSize: 14,
              flexGrow: 1,
              fontWeight: 500,
              padding: 7,
            }}
          >
            {progressTitle}
          </div>
          {/* <CloseOutlined
            style={{ cursor: "pointer" }}
            onClick={() => setOpenProgress(false)}
          /> */}
        </DialogTitle>
        <DialogContent>
          <div className="error-modal">
            {!progressError && !progressComplete ? (
              <CircularProgress style={{ fontSize: 50 }} color="info" />
            ) : null}

            {progressError && (
              <ErrorOutlined style={{ fontSize: 50 }} color="warning" />
            )}
            {progressComplete ? (
              <CheckCircleOutline style={{ fontSize: 50 }} color="success" />
            ) : null}
            <Typography
              variant="subtitle2"
              style={{ textAlign: "center", width: "100%" }}
            >
              {progressMessage !== "" ? (
                <>
                  {/* <span style={{ fontSize: 13 }}>Uploading.....</span> <br></br> */}
                  <span style={{ fontSize: 16, width: "100%" }}>
                    {progressMessage}
                  </span>
                </>
              ) : // <span>Please Login to Enrol</span>
              null}
            </Typography>
            {progressError || progressComplete ? (
              <div style={{ marginTop: 15 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenProgress(false);
                    if (handleCallBack) {
                      handleCallBack();
                    }
                  }}
                  style={{ borderColor: "green", color: "green" }}
                >
                  OK
                </Button>
              </div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProcessProgress;
