import React, { Component, useEffect, useState, useRef } from "react";
import { Typography, CircularProgress } from "@mui/material";
import { basePath } from "../util/basePath";
import UploadAnswers from "./UploadAnswers";
import CountDown from "../components/CountDown";

const ExamList = ({ entry_id, unitId, unitName, unitCode, periodId }) => {
  const [lecUnitId, setLecUnitId] = useState(null);
  const [currentExamId, setCurrentExamId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [exams, setExams] = useState([]);

  const [examEndTime, setExamEndTime] = useState(0);
  const [timeDepleted, setTimeDepleted] = useState(false);

  const url = basePath.url;

  useEffect(() => {
    setError(null);
    setLoading(true);
    setLecUnitId(null);

    let postData = {
      unitId: unitId,
      unitName: unitName,
      unitCode: unitCode,
      periodId: periodId,
    };
    fetch(`${url}get-lec-course_unit_id`, {
      method: "POST",
      headers: {
        Accept: "application.json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    })
      .then((res) => {
        if (res.status >= 400 && res.status <= 600) {
          throw new Error("Error Fetching Units");
        }
        return res.json();
      })
      .then((result) => {
        setLecUnitId(result.data);
        setError(null);
        setLoading(false);

        if (!result.data) {
          setError("Course Unit E-learning Not Set");
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(err);
        alert(err.message);

        setLoading(false);
        setLecUnitId(null);
      });
  }, []);

  useEffect(() => {
    if (lecUnitId > 0) {
      setError(null);
      setLoading(true);
      setExams([]);
      fetch(`${url}get-e-exam/${lecUnitId}`, { method: "GET" })
        .then((res) => {
          if (res.status >= 400 && res.status <= 600) {
            throw new Error("Error Fetching Units");
          }
          return res.json();
        })
        .then((result) => {
          if (result.data.length > 0) {
            let start_date = new Date(
              result.data[0].start_date
            ).toLocaleDateString();
            let start_time = result.data[0].start_time;
            let duration_hours = result.data[0].duration_hours;
            let duration_minutes = result.data[0].duration_minutes;
            let examStartTime = new Date(`${start_date} ${start_time}`);
            let start_date_time = new Date(`${start_date} ${start_time}`);
            let current_time = new Date().toLocaleString("en-US", {
              timeZone: "Africa/Nairobi",
            });

            let end_time = new Date(
              start_date_time.setTime(
                start_date_time.getTime() + duration_hours * 1000 * 60 * 60
              )
            );
            end_time = new Date(
              end_time.setTime(
                end_time.getTime() + duration_minutes * 1000 * 60
              )
            );

            if (
              new Date(current_time).getTime() >= new Date(end_time).getTime()
            ) {
              setError(`Exam expired on ${new Date(end_time)}`);
              setLoading(false);
            } else if (
              new Date(current_time).getTime() <
              new Date(examStartTime).getTime()
            ) {
              setError(`Exam starts on ${new Date(examStartTime)}`);
              setLoading(false);
            } else {
              // setMilliseconds(
              //   new Date(end_time).getTime() - new Date(current_time).getTime()
              // );
              setExamEndTime(end_time);
              setExamEndTime(end_time);
              setExams(result.data);
              setError(null);
              setLoading(false);
            }
          }

          if (result.data.length < 1) {
            setError("Exam Not Set");
            setLoading(false);
          }
        })
        .catch((err) => {
          setError(err);
          alert(err.message);

          setLoading(false);
          setExams([]);
        });
    }
  }, [lecUnitId]);

  const formatTime = (duration) => {
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
  };
  return (
    <>
      {/* <p>{lecUnitId}</p> */}
      {loading ? (
        <div>
          Loading.... <CircularProgress color={"primary"} size={25} />
        </div>
      ) : null}
      {error ? (
        <div
          style={{
            padding: 10,
            width: "99%",
            margin: 10,
            boxSizing: "border-box",
            borderLeft: "3px solid crimson",
            backgroundColor: "#ffe3e3",
          }}
        >
          <Typography variant="subtitle2">
            {/* Course Unit E-Learning not set! */}
            {error}
          </Typography>
        </div>
      ) : null}

      {exams.map((exam, index) => {
        return (
          <>
            <CountDown
              examEndTime={examEndTime}
              timeDepleted={timeDepleted}
              setTimeDepleted={setTimeDepleted}
            />
            {timeDepleted ? (
              <div>
                <Typography variant="subtitle1">Exam Time is Up!</Typography>
              </div>
            ) : (
              <UploadAnswers quizId={exam.entry_id} index={index} />
            )}
          </>
        );
      })}
    </>
  );
};

export default ExamList;
