import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
const CountDown = ({ examEndTime, timeDepleted, setTimeDepleted }) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);
  const [remainingTime, setRemainingTime] = useState(null);
  const [remainingSeconds, SetRemainingSeconds] = useState(null);

  useEffect(() => {
    if (examEndTime) {
      console.log(`eXam end time:${examEndTime}`);

      const interval = setInterval(() => {
        const current_time = new Date();
        const difference =
          new Date(examEndTime).getTime() - new Date(current_time).getTime();
        SetRemainingSeconds(difference);
        if (difference <= 2) {
          setTimeDepleted(true);
        }

        const d = Math.floor(difference / (1000 * 60 * 60 * 24));
        setDays(d);
        const h = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        setHours(h);
        const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        setMinutes(m);
        const s = Math.floor((difference % (1000 * 60)) / 1000);
        setSeconds(s);
        setRemainingTime(
          new Date().toLocaleString("en-US", { timeZone: "Africa/Nairobi" })
        );

        setRemainingTime(new Date(difference).toISOString().slice(11, 19));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, []);
  return (
    <>
      {/* <table style={{ borderCollapse: "collapse", width: "auto" }}>
        <tr>
          <td style={{ textAlign: "center", padding: 10, paddingTop: 3 }}>
            Day
          </td>

          <td style={{ textAlign: "center", padding: 10, paddingTop: 3 }}>
            Hr
          </td>

          <td style={{ textAlign: "center", padding: 10, paddingTop: 3 }}>
            Min
          </td>

          <td style={{ textAlign: "center", padding: 10, paddingTop: 3 }}>
            Sec
          </td>
        </tr>
        <tr>
          <td style={{ textAlign: "center", padding: 10, paddingTop: 3 }}>
            {days}
          </td>

          <td style={{ textAlign: "center", padding: 10, paddingTop: 3 }}>
            {hours}
          </td>

          <td style={{ textAlign: "center", padding: 10, paddingTop: 3 }}>
            {minutes}
          </td>

          <td style={{ textAlign: "center", padding: 10, paddingTop: 3 }}>
            {seconds}
          </td>
        </tr>
      </table> */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 10,
        }}
      >
        {seconds > 0 ? (
          <>
            <Typography variant="subtitle2">Time Remaining:</Typography>
            <Typography variant="subtitle1" style={{ fontSize: 18 }}>
              {remainingTime}
            </Typography>
          </>
        ) : null}
      </div>
    </>
  );
};

export default CountDown;
