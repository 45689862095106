import { LOG_IN, LOG_OUT } from "./loggerTypes";

const initialState = {
  isLogged: false,
  user: null,
};

const logReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN:
      return { isLogged: true, user: action.payload };
    case LOG_OUT:
      return { ...state, isLogged: false, user: {} };

    default:
      return state;
  }
};

export default logReducer;
