import React, { Component } from "react";
import { UseSelector, useDispatch, useSelector } from "react-redux";
import { Logout } from "@mui/icons-material";
import { logout } from "../redux/logger/loggerActions";
import { Typography, Button } from "@mui/material";
import { basePath } from "../util/basePath";
import axios from "axios";
import {
  AccountCircle,
  Edit,
  EditNote,
  Email,
  LockOutlined,
  Phone,
  SystemUpdateOutlined,
} from "@mui/icons-material";

const DropDownProfileBox = () => {
  const dispatch = useDispatch();
  const logger = useSelector((state) => {
    return state.logger;
  });

  let userImage = logger.user
    ? `${basePath.userImageUrl}${logger.user.avatar}`
    : null;
  const handleLogout = async () => {
    try {
      const signout = await axios.get(`${basePath.url}logout`);
      dispatch(logout());
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 5,
        width: "100%",
      }}
    >
      <div
        className="profile-wrapper"
        style={{
          borderBottom: "1px solid #ccc",
          padding: 5,
          width: "100%",
          marginBottom: 10,
        }}
      >
        <div className="profile-image">
          {logger.user.avatar ? (
            <img className="user-img" src={userImage} alt="Profile " />
          ) : (
            <AccountCircle style={{ fontSize: 40 }} color="action" />
          )}
        </div>
        <div className="profile-name">
          <div
            style={{
              fontSize: 14,
              marginLeft: 10,
              fontWeight: 500,
              color: "#1f1e1e",
            }}
          >
            {logger.user.otherNames}
          </div>
          <div
            style={{
              fontSize: 14,
              marginLeft: 10,
              fontWeight: 500,
              color: "#1f1e1e",
            }}
          >
            {logger.user.surname}
          </div>
        </div>
      </div>
      <div className="user-details">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: 50,
          }}
        >
          <EditNote className="grey-icons" />
        </div>
        <div style={{ marginLeft: 10, height: 50 }}>
          <Typography variant="subtitle2">{logger.user.admissionNo}</Typography>
          <Typography
            variant="subtitle2"
            style={{ fontSize: 12, color: "#757474" }}
          >
            Admission Number
          </Typography>
        </div>
      </div>
      <div className="user-details">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: 50,
          }}
        >
          <Email className="grey-icons" />
        </div>
        <div style={{ marginLeft: 10, height: 50 }}>
          <Typography variant="subtitle2" style={{ color: "#074192" }}>
            {logger.user.email}
            <br />
            <span style={{ fontSize: 12, color: "#757474" }}>Email</span>
          </Typography>
        </div>
      </div>
      <div className="user-details">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: 50,
          }}
        >
          <Phone className="grey-icons" />
        </div>
        <div
          style={{
            marginLeft: 10,

            height: 50,
          }}
        >
          <Typography variant="subtitle2" size={25}>
            {logger.user.mobileNo} <br />{" "}
            <span style={{ fontSize: 12, color: "#757474" }}>Mobile</span>
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          width: "100%",
          borderTop: "1px solid #ccc",
          marginTop: 10,
          paddingTop: 5,
        }}
      >
        <Button
          size={"small"}
          color="primary"
          style={{
            fontSize: 14,
            textTransform: "capitalize",
            outlineColor: "green",
          }}
          onClick={handleLogout}
          startIcon={<Logout />}
        >
          Logout
        </Button>
        <Button
          size={"small"}
          color="info"
          style={{ fontSize: 14, textTransform: "capitalize" }}
          startIcon={<Edit />}
        >
          Update Profile
        </Button>
      </div>
    </div>
  );
};

export default DropDownProfileBox;
