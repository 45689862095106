import {
  FETCH_SEM_PERIOD_REQUEST,
  FETCH_SEM_PERIOD_FAILURE,
  FETCH_SEM_PERIOD_SUCCESS,
} from "./semPeriodTypes";

import axios from "axios";
import { basePath } from "../../util/basePath";

const url = basePath.url;

export const fetchSemPeriodRequest = () => {
  return {
    type: FETCH_SEM_PERIOD_REQUEST,
  };
};

export const fetchSemPeriodFailure = (error) => {
  return {
    type: FETCH_SEM_PERIOD_FAILURE,
    payload: error,
  };
};

export const fetchSemPeriodSuccess = (semPeriod) => {
  return {
    type: FETCH_SEM_PERIOD_SUCCESS,
    payload: semPeriod,
  };
};

export const fetchSemPeriod = () => {
  return function (dispatch) {
    dispatch(fetchSemPeriodRequest());

    axios
      .get(`${url}sem-period/sem-period-current/`)
      .then((response) => {
        const period = response.data.data;

        dispatch(fetchSemPeriodSuccess(period));
      })
      .catch((err) => {
        dispatch(fetchSemPeriodFailure(err.message));
      });
  };
};
