import { combineReducers } from "redux";
import logReducer from "./logger/loggerReducer";
import semPeriodReducer from "./semPeriod/semPeriodReducer";
import semUnitsReducer from "./semUnits/semUnitsReducer";

const rootReducer = combineReducers({
  logger: logReducer,
  semPeriodReducer,
  semUnitsReducer,
});

export default rootReducer;
