import React, { Component, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Person2,
  School,
  lock,
  Send,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { CircularProgress, Typography, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import styles from "./login.module.css";
import logo from "../../assets/icon.png";
import { login, logout } from "../../redux/logger/loggerActions";
import { fetchCurrentSemUnits } from "../../redux/semUnits/semUnitsActions";
import { fetchSemPeriod } from "../../redux/semPeriod/semPeriodActions";
import { basePath } from "../../util/basePath";
import Axios from "axios";
import axios from "axios";

import * as Yup from "yup";
import { Formik, Field, ErrorMessage, Form } from "formik";
import ErrorText from "../ErrorText";

const LoginPage = () => {
  const url = basePath.url;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const login_status = useSelector((state) => state.logger);
  const dispatch = useDispatch();

  const [loginMessage, setLoginMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [showPassword, setShowPassword] = useState(true);

  const navigate = useNavigate();
  Axios.defaults.withCredentials = true;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoginData(null);
    setLoading(true);
    setLoginError(null);
    const data = {
      username: userName,
      password: password,
    };

    const baseUrl = `${url}login`;
    try {
      const result = await axios.post(baseUrl, data);

      setLoading(false);
      setLoginError(null);

      if (result.data.message === "success") {
        dispatch(login(result.data.data));
        dispatch(fetchSemPeriod());
      } else {
        setLoginError(result.data.description);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setLoginError(err.message);
      console.log(err);
    }
  };

  const logger = useSelector((state) => {
    return state.logger;
  });
  const semPeriod = useSelector((state) => {
    return state.semPeriodReducer;
  });
  const semPeriodId = useSelector((state) => {
    return state.semPeriodReducer?.currentSemPeriod?.periodID;
  });
  // const admission_no = useSelector((state) => {
  //   return state.logger?.user?.admissionNo;
  // });

  const onSubmitLogin = async (values, onSubmitProps) => {
    setLoginData(null);
    setLoading(true);
    setLoginError(null);
    const data = {
      username: values.userName.trim(),
      password: values.password.trim(),
    };

    const baseUrl = `${url}login`;
    try {
      const result = await axios.post(baseUrl, data);

      setLoading(false);
      setLoginError(null);

      if (result.data.message === "success") {
        //console.log(result.data.user);
        dispatch(fetchSemPeriod());
        dispatch(login(result.data.user));
        // dispatch(fetchSemPeriod());
        // console.log(
        //   `sem period id: ${semPeriodId} admission  number:${result.data.user.admissionNo}`
        // );
        // dispatch(
        //   fetchCurrentSemUnits(result.data.user.admissionNo, semPeriodId)
        // );

        onSubmitProps.setSubmitting(false);
        navigate("/");
      } else {
        setLoginError(result.data.description);
        setLoading(false);
        onSubmitProps.setSubmitting(false);
      }
    } catch (err) {
      setLoading(false);
      setLoginError(err.message);
      // console.log(err);
      onSubmitProps.setSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(fetchCurrentSemUnits(logger?.user?.admissionNo, semPeriodId));
  }, [logger]);

  const initialValues = {
    userName: "",
    password: "",
  };

  const validationSchema = Yup.object({
    userName: Yup.string().required("Please enter Admission Number"),
    password: Yup.string().required("Password is required"),
  });
  return (
    <>
      <div className={"loginOuterPrimary"}>
        <div className={"loginOuterSecondary"}>
          <div className={"loginInner"}>
            <div className={"item"}>
              <div className={"imageWrapper"}>
                <img
                  src={logo}
                  alt="logo"
                  style={{ height: "180px", width: "auto" }}
                />
              </div>
              <Typography variant="subtitle2" style={{ fontSize: "18px" }}>
                TEAU STUDENTS PORTAL
              </Typography>
            </div>
          </div>

          <div
            className={"loginInner"}
            style={{ height: "100%", color: "orange" }}
          >
            <div
              className={"itemRight"}
              style={{
                width: "100%",

                height: "100%",
              }}
              //   style={{ background: "rgb(181, 207, 181)" }}
            >
              <div className={styles.loginMessage}>
                {loginError && loginError}
                {loading && (
                  <>
                    <p>Loading ....</p>
                    <CircularProgress color={"primary"} size={25} />
                  </>
                )}
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmitLogin}
                validationSchema={validationSchema}
                style={{ width: "100%" }}
              >
                {(formikProps) => {
                  return (
                    <Form
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "15%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 5,
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexGrow: 1,
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Field
                            type="text"
                            name={"userName"}
                            id={"userName"}
                            placeholder="Admission Number"
                            style={{
                              fontSize: 16,
                              padding: 5,
                              color: "#272626",
                            }}
                          />
                          <ErrorMessage name="userName" component={ErrorText} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "left",
                            flexGrow: 1,
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              padding: 0,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ width: "90%" }}>
                              <Field
                                placeholder="Password"
                                type={showPassword ? "password" : "text"}
                                name={"password"}
                                id={"password"}
                                style={{
                                  fontSize: 16,
                                  padding: 5,
                                  color: "#272626",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                width: "10%",

                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {!showPassword ? (
                                <VisibilityOff
                                  size={50}
                                  color="success"
                                  onClick={() => {
                                    return setShowPassword(!showPassword);
                                  }}
                                />
                              ) : (
                                <Visibility
                                  size={50}
                                  color="success"
                                  onClick={() => {
                                    return setShowPassword(!showPassword);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <ErrorMessage name="password" component={ErrorText} />
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          marginTop: 25,
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="success"
                          size="medium"
                          //endIcon={<Send />}
                          //style={{ width: "100%", backgroundColor: "green" }}
                          //className={styles.submitButton}
                          style={{ width: "100%" }}
                          disabled={
                            !formikProps.isValid || formikProps.isSubmitting
                          }
                        >
                          Login
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
