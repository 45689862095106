import React, { Component, useEffect, useState, useRef } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useQuery } from "react-query";

import { useSelector } from "react-redux";
import axios from "axios";
import Axios from "axios";
import { Typography, Button, CircularProgress } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import {
  ErrorOutlined,
  ArrowForward,
  Edit,
  Lock,
  LockOpen,
  PrintOutlined,
  DownloadOutlined,
  Close,
} from "@mui/icons-material";

import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ProcessProgress from "../../components/ProcessProgress";
import styles from "../../styles/sidenav.module.css";
import { basePath } from "../../util/basePath";
import UnitsList from "../../components/courseUnits/UnitsList";
import logo from "../../assets/icon.png";
import LineSeparaor from "../../components/lec_evaluation/line_separator";
import LecEvaluationGuidelines from "./LecEvaluationGuidelines";
import EvaluationForm from "../../components/lec_evaluation/evaluation_form";

// const fetchUnits = (period_id, admission_no) => {
//   return axios.get(
//     `${basePath.url}sem-registration/registered-units?admission_no=${admission_no}&period_id=${period_id}`
//   );
// };

// const fetchFeesStatus = (period_id, admission_no) => {
//   return axios.get(
//     `${basePath.url}fees/fees-balance?admission_no=${admission_no}&period_id=${period_id}`
//   );
// };

const LecturerEvaluation = ({ currentSelectedUnit, openCard, setOpenCard }) => {
  Axios.defaults.withCredentials = true;
  const [isLoadingUnits, setIsloadingUnits] = useState(false);
  const [isErrorUnits, setIsErrorUnits] = useState(null);
  const [registeredUnits, setRegisteredUnits] = useState(null);

  const [isLoadingFees, setIsloadingFees] = useState(false);
  const [isErrorFees, setIsErrorFees] = useState(null);
  const [feeStatus, setFeeStatus] = useState(null);

  const [feeIssue, setFeeIssue] = useState(false);
  const [registrationIssue, setRegistrationIssue] = useState(false);

  // const [openCard, setOpenCard] = useState(false);
  const [studentSession, setStudentSession] = useState(null);

  const [examCardSerial, setExamCardSerial] = useState(null);

  const [openProgress, setOpenProgress] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const [progressTitle, setProgressTitle] = useState("");

  const printRef = useRef(null);

  const semPeriod = useSelector((state) => {
    return state.semPeriodReducer;
  });
  const semPeriodId = useSelector((state) => {
    return state.semPeriodReducer?.currentSemPeriod?.periodID;
  });
  const admission_no = useSelector((state) => {
    return state.logger?.user?.admissionNo;
  });

  const studentName = useSelector((state) => {
    return state.logger?.user?.names;
  });

  const studentCourse = useSelector((state) => {
    return state.logger?.user?.courseName;
  });

  const logger = useSelector((state) => {
    return state.logger;
  });

  let userImage = logger.user
    ? `${basePath.userImageUrl}${logger.user.avatar}`
    : null;

  // const admission_no = useSelector((state) => {
  //   return state.logger?.user?.admissionNo;
  // });

  const loadDetails = async () => {
    try {
      setIsloadingUnits(true);
      setIsErrorUnits(null);
      setRegisteredUnits(null);
      const units = await axios.get(
        `${basePath.url}sem-registration/registered-units?admission_no=${admission_no}&period_id=${semPeriodId}`
      );

      setIsloadingUnits(false);
      setIsErrorUnits(null);

      setRegisteredUnits(units.data.data);

      setStudentSession(units?.data.data[0]?.sessionName);

      setExamCardSerial(units?.data.data[0]?.examSerial);

      //load fee status
      setIsloadingFees(true);
      setIsErrorFees(null);
      setFeeStatus(null);
      const fee_status = await axios.get(
        `${basePath.url}fees/fees-balance?admission_no=${admission_no}&period_id=${semPeriodId}`
      );

      let fee_details = fee_status.data.data;

      //set fee issue
      if (
        fee_details.fee_balance > 0 &&
        fee_details.examExemptionStatus == false
      ) {
        setFeeIssue(true);
      }

      //set registration issue
      if (units.data.data.length < 1) {
        setRegistrationIssue(true);
      }

      for (let i = 0; i < units.data.data.length; i++) {
        let currentUnit = units.data.data[i];
        if (currentUnit.isEvaluated == false) {
          setRegistrationIssue(true);
          break;
        }
      }

      setIsloadingFees(false);
      setIsErrorFees(null);
      setFeeStatus(fee_status.data.data);

      // console.log(units);
      // console.log(fee_status);
    } catch (error) {
      setIsErrorUnits(error.message);
    }
  };

  useEffect(() => {
    loadDetails();
  }, []);

  useEffect(() => {
    if (currentSelectedUnit) {
      setOpenCard(true);
    }
  }, [currentSelectedUnit]);

  // const {
  //   isLoading: isLoadingUnits,
  //   data: dataUnits,
  //   isError: isErrorUnits,
  //   error: errorUnits,
  //   isFetching: isFecthingUnits,
  //   // refetch: refetchUnits,
  // } = useQuery("registered_units", fetchUnits(semPeriodId, admission_no), {
  //   staleTime: 30000,
  // });
  // const {
  //   isLoading: isLoadingFees,
  //   data: dataFees,
  //   isError: isErrorFees,
  //   error: errorFees,
  //   isFetching: isFecthingFees,
  //   // refetch: refetchFees,
  // } = useQuery("fee_status", fetchFeesStatus(semPeriodId, admission_no), {
  //   staleTime: 30000,
  // });
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={openCard}
        onClose={() => {
          setOpenCard((state) => {
            return !state;
          });
        }}

        // PaperProps={{ sx: { height: "100%" } }}
      >
        <DialogTitle
          borderBottom={1}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 600, fontSize: 18 }}
          >
            Lecturer Evaluation
          </Typography>
          <Close
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenCard((state) => {
                return !state;
              });
            }}
          />{" "}
        </DialogTitle>
        <DialogContent>
          <div style={{ width: "100%", minHeight: 300 }}>
            {currentSelectedUnit ? (
              <EvaluationForm
                currentSelectedUnit={currentSelectedUnit}
                sem_period={semPeriodId}
                admissionNo={admission_no}
                openCard={openCard}
                setOpenCard={setOpenCard}
              />
            ) : (
              <LecEvaluationGuidelines />
            )}
          </div>
        </DialogContent>
      </Dialog>

      <ProcessProgress
        openProgress={openProgress}
        setOpenProgress={setOpenProgress}
        progressMessage={progressMessage}
        progressTitle={progressTitle}
      />

      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flex: 1,
            flexWrap: "wrap",
          }}
        >
          <div className={styles.topContent}>
            <Typography
              variant="h6"
              style={{ fontWeight: 400, color: "#282727", paddingLeft: 10 }}
            >
              Lecturer Evaluation
            </Typography>
          </div>
          <div className="plainContent">
            <Typography
              variant="subtitle2"
              style={{ fontSize: "16px", color: "#3c3d3c" }}
            >
              Current Semester Period:{" "}
              <div style={{ color: "#507b50", display: "inline-block" }}>
                {semPeriod?.currentSemPeriod?.periodName}
              </div>
            </Typography>
          </div>
        </div>
      </div>
      <LineSeparaor />

      {/* {currentSelectedUnit ? (
        <EvaluationForm
          currentSelectedUnit={currentSelectedUnit}
          sem_period={semPeriodId}
          admissionNo={admission_no}
        />
      ) : (
        <LecEvaluationGuidelines />
      )} */}
      <LecEvaluationGuidelines />
    </>
  );
};

export default LecturerEvaluation;
