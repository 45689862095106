import React, { Component, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

import logo from "../../assets/icon.png";
import { basePath } from "../../util/basePath";

const ExamCardPdf = ({
  examCardSerial,
  registeredUnits,
  studentSession,
  semPeriod,
  admission_no,
  studentName,
  studentCourse,
  avatar,
  userImage,
}) => {
  // const semPeriod = useSelector((state) => {
  //   return state.semPeriodReducer;
  // });
  // const semPeriodId = useSelector((state) => {
  //   return state.semPeriodReducer?.currentSemPeriod?.periodID;
  // });
  // const admission_no = useSelector((state) => {
  //   return state.logger?.user?.admissionNo;
  // });

  // const studentName = useSelector((state) => {
  //   return state.logger?.user?.names;
  // });

  // const studentCourse = useSelector((state) => {
  //   return state.logger?.user?.courseName;
  // });

  // const logger = useSelector((state) => {
  //   return state.logger;
  // });

  // let userImage = logger.user
  //   ? `${basePath.userImageUrl}${logger.user.avatar}`
  //   : null;

  const style = StyleSheet.create({
    //top serial number and profile image header
    page: {
      position: "relative",
      padding: "20px",
      paddingTop: "30px",
    },
    topHeaderContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginBottom: "20px",
    },
    topHeaderSerialText: {
      fontSize: "12px",
      letterSpacing: "2px",
      marginBottom: "3px",
    },
    profileImgView: {
      border: "1px solid #ccc",
      width: 80,
      height: 80,
      boxSizing: "border-box",
      padding: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    profileImg: {
      width: 80,
      height: "auto",
    },

    // image header

    midHeaderContainer: {
      dispay: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "black",
      width: "100%",
    },

    logoOuterContainer: {
      width: "100%",
      dispay: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    logoInnerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      width: "100px",
      height: "auto",
    },
    topHeaderText: {
      fontSize: "16px",
      textAlign: "center",
      fontWeight: "bold",
      letterSpacing: "2px",
      marginBottom: "3px",
    },
    topMidText: {
      fontSize: "14px",
      textAlign: "center",
      fontWeight: 600,
      letterSpacing: "2px",
      marginBottom: "3px",
    },
    topBottomText: {
      fontSize: "14px",
      textAlign: "center",
      fontWeight: 600,
      letterSpacing: "2px",
      marginBottom: "3px",
    },
    top_bottom_text: {
      fontSize: "12px",
      textAlign: "center",
      fontWeight: 600,
      letterSpacing: "2px",
      marginBottom: "3px",
    },
    //stuent details
    studentDetailsContainer: {
      dispay: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "black",
      width: "100%",
      marginTop: 70,
    },
    studentDetailsWrapper: {
      width: "100%",
      dispay: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      // gap: 5,
      marginBottom: "3px",
    },
    studentDetailsLeftView: {
      fontSize: "11px",
      fontWeight: "bold",
      letterSpacing: "2px",
      marginBottom: "5px",
      marginLeft: 0,
    },
    studentDetailsRightView: {
      fontSize: "11px",
      fontWeight: 300,
      letterSpacing: "2px",
      marginBottom: "5px",
      marginLeft: 0,
    },
    studentDetailsText: {
      fontSize: "11px",
      fontWeight: 500,
      letterSpacing: "2px",
      marginBottom: "3px",
      marginLeft: 0,
    },

    //notes
    notesContainer: {
      dispay: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "black",
      width: "100%",
      marginTop: 50,
      // marginBottom: 100,
    },
    noteHeaderTextWrapper: {
      borderBottom: "1px solid #ccc",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 30,
    },
    noteHeaderText: {
      textAlign: "center",
      fontSize: "14px",
    },
    noteItems: {
      fontSize: "10px",
      fontWeight: 500,
      letterSpacing: "2px",
      marginBottom: "7px",
    },

    //registered units
    unitsContainer: {
      width: "!00%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },

    //watermark
    watermark: {
      position: "absolute",
      top: "30%",
      left: "25%",
      width: 450,
      height: 450,
      opacity: 0.1,
      transform: "translate(-50%,-50%)",
    },
    //TABLE
    tableHeader: {
      flexDirection: "row",
      border: 1,
      borderColor: "#272626",
      backgroundColor: "#ccc",
      fontWeight: "bold",
    },
    tableColumn1: {
      width: "5%",
      textAlign: "left",
      fontSize: "9px",
      // paddingBottom: "10px",
      // paddingTop: "10px",
      // marginLeft: "11px",
      padding: "5px",
    },
    tableColumn2: {
      width: "13%",
      textAlign: "left",
      fontSize: "9px",
      border: 1,
      borderColor: "#272626",
      borderBottom: "0px",
      // paddingBottom: "10px",
      // paddingTop: "10px",
      // paddingLeft: "11px",
      padding: "5px",
    },
    tableColumn3: {
      width: "40%",
      textAlign: "left",
      fontSize: "9px",
      border: 1,
      borderColor: "#272626",
      borderBottom: "0px",
      // paddingBottom: "10px",
      // paddingTop: "10px",
      // paddingLeft: "11px",
      padding: "5px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    tableColumn4: {
      width: "30%",
      textAlign: "left",
      fontSize: "9px",
      border: 1,
      borderColor: "#272626",
      borderBottom: "0px",
      // paddingBottom: "10px",
      // paddingTop: "10px",
      // paddingLeft: "11px",
      padding: "5px",
    },
    tableColumn5: {
      width: "12%",
      textAlign: "left",
      fontSize: "9px",
      border: 1,
      borderColor: "#272626",
      borderBottom: "0px",
      // paddingBottom: "10px",
      // paddingTop: "10px",
      // paddingLeft: "11px",
      padding: "5px",
    },
    tableRow: {
      flexDirection: "row",
      border: 1,
      borderColor: "#272626",
      borderTop: "none",
    },
    generalText: {
      fontSize: "9px",
      letterSpacing: "2px",
      marginBottom: "3px",
    },
    noImageText: {
      fontSize: "9px",
      letterSpacing: "2px",
      marginBottom: "3px",
      textAlign: "center",
    },
  });
  useEffect(() => {
    console.log(userImage);
  }, []);
  return (
    <Document>
      <Page size={"A4"} style={style.page}>
        {/* <image src={logo} style={style.watermark} /> */}

        <View style={style.topHeaderContainer}>
          <View style={style.profileImgView}>
            {userImage ? (
              <Image
                style={style.profileImg}
                src={userImage}
                alt="Profile Image"
              />
            ) : (
              <Text style={style.noImageText}>No Passport Image</Text>
            )}
          </View>
          <View>
            <Text style={style.topHeaderSerialText}>
              {examCardSerial && examCardSerial}
            </Text>
          </View>
        </View>

        <View style={style.midHeaderContainer}>
          <View style={style.logoOuterContainer}>
            <View style={style.logoInnerContainer}>
              <Image style={style.logo} src={logo} alt="logo" />
            </View>
          </View>
          <View>
            <Text style={style.topHeaderText}>The East African University</Text>
          </View>
          <View>
            <Text style={style.topMidText}>Examination Card</Text>{" "}
          </View>
          <View>
            <Text style={style.top_bottom_text}>
              {semPeriod?.currentSemPeriod?.periodName}
            </Text>
          </View>
        </View>

        <View style={style.studentDetailsContainer}>
          <View style={style.studentDetailsWrapper}>
            <View>
              <Text style={style.studentDetailsLeftView}>Name: </Text>
            </View>
            <View>
              <Text style={style.studentDetailsRightView}>{studentName}</Text>
            </View>
          </View>
          <View style={style.studentDetailsWrapper}>
            <View>
              <Text style={style.studentDetailsLeftView}>
                Registration Number:{" "}
              </Text>
            </View>
            <View>
              <Text style={style.studentDetailsRightView}>{admission_no}</Text>
            </View>
          </View>
          <View style={style.studentDetailsWrapper}>
            <View>
              <Text style={style.studentDetailsLeftView}>Course: </Text>
            </View>
            <View>
              <Text style={style.studentDetailsRightView}>{studentCourse}</Text>
            </View>
          </View>
          <View style={style.studentDetailsWrapper}>
            <View>
              <Text style={style.studentDetailsLeftView}>Year of Study: </Text>
            </View>
            <View>
              <Text style={style.studentDetailsRightView}>
                {studentSession}
              </Text>
            </View>
          </View>
          <View style={style.studentDetailsWrapper}>
            <View>
              <Text style={style.studentDetailsLeftView}>
                Semester Period:{" "}
              </Text>
            </View>
            <View>
              <Text style={style.studentDetailsRightView}>
                {semPeriod?.currentSemPeriod?.periodName}
              </Text>
            </View>
          </View>
        </View>

        <View style={style.notesContainer}>
          <View style={style.noteHeaderTextWrapper}>
            <Text style={style.noteHeaderText}>Note</Text>
          </View>
          <View>
            <Text style={style.noteItems}>
              1. Bring this card with you whenever you are coming for your
              examination
            </Text>
            <Text style={style.noteItems}>
              2. Ensure you sign the invigilator's form for the subject done in
              each session
            </Text>
            <Text style={style.noteItems}>
              3. Submit this card to Academic Registrar's office immediately
              after the examination period is over
            </Text>
            <Text style={style.noteItems}>
              4. This card is confidential and it must be produced to the
              invigilator when required during each examination
            </Text>
            <Text style={style.noteItems}>
              5. The name by which you are officially registered at the
              university is the name that will appear on the
              degree/diploma/certificate. Any error must be communicated to the
              registrar before the end of the examination.(Applicable to final
              year students only)
            </Text>
          </View>
        </View>
      </Page>
      <Page size={"A4"} style={style.page}>
        <View style={style.tableHeader}>
          <Text style={style.tableColumn1}>S/N</Text>
          <Text style={style.tableColumn2}>UNIT CODE</Text>
          <Text style={style.tableColumn3}>UNIT TITLE</Text>
          <Text style={style.tableColumn4}>LECTURER</Text>
          <Text style={style.tableColumn5}>SIGN</Text>
        </View>
        {registeredUnits &&
          registeredUnits.map((internship, index) => {
            return (
              <View style={style.tableRow} key={index}>
                <Text style={style.tableColumn1}> {index + 1}</Text>
                <Text style={style.tableColumn2}> {internship.unitCode}</Text>
                <Text style={style.tableColumn3}>{internship.unitName}</Text>
                <Text style={style.tableColumn4}>
                  {" "}
                  {internship?.names ? internship.names.toUpperCase() : "N/A"}
                </Text>
                <Text style={style.tableColumn5}></Text>
              </View>
            );
          })}
      </Page>
    </Document>
  );
};

export default ExamCardPdf;
