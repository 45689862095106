import React, { Component, useState, useEffect } from "react";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { Typography } from "@mui/material";
const UnitsList = ({ units, handleSelectedUnit }) => {
  useEffect(() => {
    // console.log(units);
  }, []);
  return (
    <>
      {units.length > 0 &&
        units.map((unit) => {
          let evaluationStatus = typeof unit.isEvaluated;

          return (
            <div
              key={unit.entry_id}
              className="unitsWrapper"
              style={{ borderBottom: "1px solid #ccc", flexWrap: "wrap" }}
              // onClick={() => handleSelectedUnit(unit)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <div>
                  <Typography
                    variant="subtitle2"
                    style={{ color: "#3c3d3c", fontSize: 14 }}
                  >
                    {unit.unitCode}
                  </Typography>{" "}
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 500, fontSize: 14 }}
                  >
                    {unit.unitName}
                  </Typography>
                </div>
              </div>

              {evaluationStatus !== "undefined" ? (
                unit.isEvaluated == true ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#282727" }}
                    >
                      Evaluated
                    </Typography>
                    <CheckOutlinedIcon
                      style={{ fontSize: 20, color: "green" }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ color: "crimson" }}
                    >
                      Not Evaluated
                    </Typography>
                    <ClearOutlinedIcon
                      style={{ fontSize: 20, color: "crimson" }}
                    />
                  </div>
                )
              ) : null}
              {evaluationStatus == "undefined" ? (
                <div>
                  <ArrowForwardIosOutlined
                    style={{ color: "grey", fontSize: 15 }}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
    </>
  );
};

export default UnitsList;
