import { useEffect, useState } from "react";
import { UseSelector, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Button, CircularProgress } from "@mui/material";
import {
  ErrorOutlined,
  ArrowForward,
  Edit,
  Lock,
  LockOpen,
  AlternateEmail,
  Mail,
  Phone,
  Person,
  AppRegistrationOutlined,
} from "@mui/icons-material";
import { basePath } from "../../util/basePath";
import styles from "../../styles/sidenav.module.css";
import axios from "axios";

const DisplayProject = ({ id, setOpenViewInternship }) => {
  const [internshipList, setInternshipList] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentUserId, setCurrentUserId] = useState("");
  const [startDate, setStartDate] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setError(null);

    const getList = async () => {
      try {
        const list = await axios.get(`${basePath.url}project/${id}`);

        if (list.data.data.length > 0) {
          setInternshipList(list.data.data);

          if (list.data.data[0].lecturer_assigned_id > 0) {
            const employee = await axios.get(
              `${basePath.url}users/get-by-user-id/${list.data.data[0].lecturer_assigned_id}`
            );

            if (employee.data.data.length > 0) {
              setEmployeeDetails(employee.data.data[0]);
            }
          }

          setLoading(false);
          setError(null);
        } else {
          setLoading(false);
          setError(null);
        }
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };
    getList();
  }, []);

  return (
    <>
      {/* <ErrorModal /> */}

      {loading === true ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "50px",
          }}
        >
          <CircularProgress />
        </div>
      ) : !internshipList || internshipList.length < 1 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "50px",
          }}
        >
          <ErrorOutlined style={{ fontSize: 50 }} color="warning" />
          <Typography variant="h6" style={{ textAlign: "center" }}>
            Project / Proposal not Found
          </Typography>
          <Button
            variant="outlined"
            color="success"
            size={"medium"}
            endIcon={<ArrowForward />}
            onClick={() => {
              navigate("/academic/project-list");
              setOpenViewInternship(false);
            }}
          >
            Projects list
          </Button>
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 3,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {internshipList[0].status === "OPEN" ? (
                <LockOpen color="success" />
              ) : (
                <Lock color="warning" />
              )}
              <Typography
                variant="subtitle1"
                style={{ fontSize: 12, paddingRight: 10 }}
              >
                {internshipList[0].status.toLowerCase()}
              </Typography>
            </div>
          </div>
          <div
            className={styles.mainContent}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 13 }}>Level :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {internshipList[0].project_level}
                </Typography>
              </div>

              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 13 }}> Date Created :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {new Date(internshipList[0].createdAt).toDateString()}
                </Typography>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 13 }}>Project Fees Status :</label>
                <Typography
                  variant={"subtitle2"}
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {internshipList[0].project_fees_status}
                </Typography>
              </div>

              <div
                className="search_box"
                style={{ width: "auto", marginRight: 10 }}
              >
                <label style={{ fontSize: 13 }}>Lecturer Assigned :</label>
                <Typography
                  variant="subtitle1"
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {employeeDetails ? (
                    employeeDetails.names.toUpperCase()
                  ) : (
                    <span>{internshipList[0].lecturer_assigned_id}</span>
                  )}
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles.mainContent}>
            <div className={styles.formSubContent}>
              <div className="formInput">
                <label style={{ fontSize: 13 }}>Project Title :</label>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: 12, paddingLeft: 5 }}
                >
                  {internshipList[0].project_title}
                </Typography>
              </div>
            </div>
          </div>
          <div
            className={styles.mainContent}
            style={{
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div className={styles.formSubContent}>
                <div className="formInput">
                  <label style={{ fontSize: 13 }}>Project Summary:</label>
                  <Typography
                    variant="subtitle2"
                    style={{ fontSize: 12, paddingLeft: 5, maxWidth: 600 }}
                  >
                    {internshipList[0].project_summary}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              border: "none",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <div className="formInput">
              <label style={{ fontSize: 13 }}> Comments:</label>
            </div>

            <div>
              {internshipList[0].comments
                .sort((a, b) => {
                  let fa = new Date(a.createdAt);
                  let fb = new Date(b.createdAt);

                  if (fa > fb) {
                    return -1;
                  } else if (fb > fa) {
                    return 1;
                  } else {
                    return 0;
                  }
                })
                .map((comment, index) => {
                  return (
                    <div className="chat-wrapper" key={comment.createdAt}>
                      <div className="chat-header">
                        {comment.type == "progress" ? (
                          <>
                            <span className="chat-username">Student</span>
                            <span className="chat-timestamp">
                              {new Date(comment.createdAt).toLocaleString()}
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="chat-username">
                              {comment.comment_type}
                            </span>
                            <span className="chat-username">
                              {comment.user_name.toUpperCase()}
                            </span>
                            <span className="chat-timestamp">
                              {new Date(comment.createdAt).toLocaleString()}
                            </span>
                          </>
                        )}
                      </div>
                      <div className="chat-body">
                        <Typography
                          variant="subtitle1"
                          style={{ fontSize: "13px" }}
                        >
                          {comment.comment}
                          {comment.description}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DisplayProject;
