import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  CircularProgress,
} from "@mui/material";
import { CloseOutlined, FileUploadOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { basePath } from "../util/basePath";
const UploadAnswers = ({ quizId, index }) => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const [errorUploadingFile, setErrorUploadingFile] = useState(null);
  const [successUploadingFile, setSuccessUploadingFile] = useState(false);

  const [loadingFiles, setLoadingFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [uploadedFilesError, setUploadedFilesError] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [filesUploaded, setFilesUploaded] = useState(null);
  const url = basePath.url;
  const user = useSelector((state) => {
    return state.logger.user;
  });

  const refreshFiles = async (admissionNo, quizId) => {
    setLoadingFiles(true);
    setUploadedFilesError(null);
    try {
      const data = {
        quizId: quizId,
        admissionNo: admissionNo,
      };
      //console.log(`quizid: ${quizId} admissionNo: ${admissionNo}`);
      const uploaded = await axios.post(`${url}uploaded_exam_files`, data);

      if (uploaded.data.data.length > 0) {
        setUploadedFiles(uploaded.data.data);
        // console.log(uploaded.data.data);
      }

      setLoadingFiles(false);
      setUploadedFilesError(null);
    } catch (error) {
      setLoadingFiles(false);
      setUploadedFilesError(error);
    }
  };
  useEffect(() => {
    const getFiles = async (admissionNo, quizId) => {
      setLoadingFiles(true);
      setUploadedFilesError(null);
      try {
        const data = {
          quizId: quizId,
          admissionNo: admissionNo,
        };
        // console.log(`quizid: ${quizId} admissionNo: ${admissionNo}`);
        const uploaded = await axios.post(`${url}uploaded_exam_files`, data);

        if (uploaded.data.data.length > 0) {
          setUploadedFiles(uploaded.data.data);
          // console.log(uploaded.data.data);
        }

        setLoadingFiles(false);
        setUploadedFilesError(null);
      } catch (error) {
        setLoadingFiles(false);
        setUploadedFilesError(error);
      }
    };

    getFiles(user.admissionNo, quizId);
  }, []);

  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onClickHandler = () => {
    setUploadingFile(true);
    setErrorUploadingFile(true);
    setSuccessUploadingFile(false);
    const data = new FormData();

    data.append("admissionNo", user.admissionNo);
    data.append("quizId", quizId);
    data.append("file", selectedFile);
    axios
      .post(`${url}upload`, data, {
        // receive two    parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        if (res.data.message === "success") {
          setUploadingFile(false);
          setErrorUploadingFile(false);
          setSuccessUploadingFile(true);

          refreshFiles(user.admissionNo, quizId);
          //console.log(res.data.file);
        } else {
          setUploadingFile(false);
          setErrorUploadingFile(true);
          setSuccessUploadingFile(false);
        }
      })
      .catch((err) => {
        //console.log(err.message);
        setUploadingFile(false);
        setErrorUploadingFile(true);
        setSuccessUploadingFile(false);
      });
  };

  return (
    <>
      <div
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          width: "100%",
        }}
        key={index}
      >
        <div>
          <div className="formInput">
            <Typography variant="subtitle2">
              Please select File and Upload
            </Typography>
            <input type="file" name="file" onChange={onChangeHandler} />
          </div>
          {successUploadingFile ? (
            <div
              style={{
                padding: 10,
                width: "99%",
                margin: 10,
                boxSizing: "border-box",
                backgroundColor: "#def5ed",
                borderLeft: "3px solid green",
              }}
            >
              <Typography variant="subtitle2">
                File Uploaded Successfully!
              </Typography>
            </div>
          ) : null}
          {errorUploadingFile ? (
            <div
              style={{
                padding: 10,
                width: "99%",
                margin: 10,
                boxSizing: "border-box",
                borderLeft: "3px solid crimson",
                backgroundColor: "#ffe3e3",
              }}
            >
              <Typography variant="subtitle2">
                Error Uploading File! Please try again
              </Typography>
            </div>
          ) : null}
          {uploadingFile ? (
            <CircularProgress color="info" />
          ) : (
            <>
              <br />
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                startIcon={<FileUploadOutlined size="medium" color="grey" />}
                onClick={onClickHandler}
                style={{ marginBottom: 50 }}
              >
                Upload
              </Button>
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {uploadedFiles && (
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              Files Uploaded
            </Typography>
          )}
          {uploadedFiles &&
            uploadedFiles.map((item, index) => {
              return (
                <div key={item.entry_id}>
                  <Typography variant="subtitle2" style={{ fontSize: 11 }}>
                    {item.file_name}
                  </Typography>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default UploadAnswers;
