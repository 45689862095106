import React, { Component } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { Spinner } from "react-activity";

import { ErrorOutlined } from "@mui/icons-material";
const LecEvaluationGuidelines = () => {
  const studentName = useSelector((state) => {
    return state.logger?.user?.names;
  });

  const semUnitsRegistered = useSelector((state) => {
    return state.semUnitsReducer.registeredUnits;
  });

  const semUnits = useSelector((state) => {
    return state.semUnitsReducer;
  });
  return (
    <>
      {semUnitsRegistered && semUnitsRegistered.length > 0 ? (
        <div style={{ padding: 20 }}>
          <br></br>
          <Typography variant="subtitle2" style={{ fontSize: 16 }}>
            Hello{" "}
            <span style={{ fontWeight: 600 }}>{studentName.split(" ")[0]}</span>
            , In an effort to improve the quality of the courses and services
            provided, we seek for your feedback to help improve on services.
            Kindly fill in these forms to fulfill the requirements for this
            survey, which should take approximately 15-20 minutes of your time.
            This exercise shall be treated confidentially.
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: 20, fontWeight: 600, fontSize: 18 }}
          >
            Course Evaluation
          </Typography>

          <Typography
            variant="subtitle2"
            style={{ marginTop: 10, marginBottom: 10, fontSize: 14 }}
          >
            <strong>
              Indicate your level of agreement to the following statement using
              this scale:
            </strong>
          </Typography>
          <div style={{ margin: 15 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: 16,
                flexWrap: "wrap",
                gap: 20,
              }}
            >
              <div>(1) Strongly disagree (SD)</div>
              <div>(2) Disagree (D) </div>
              <div>(3) Neutral (N)</div>
              {/* </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: 16,
              flexWrap: "wrap",
            }}
          > */}
              <div>(4) Agree (A)</div>
              <div>(5) Strongly Agree (SA)</div>
              <div>(6) Not Applicable (NA)</div>
            </div>
          </div>

          <Typography
            variant="subtitle1"
            style={{ fontSize: 16, marginTop: 15 }}
          >
            If the statement does not apply to your situation, mark as Not
            Applicable (N/A).
          </Typography>
          <p
            style={{
              backgroundColor: "#ebd302",
              padding: "2px",
              marginTop: 20,
              marginBottom: 20,
              padding: 15,
              borderRadius: "8px",
            }}
          >
            NB: BE OBJECTIVE IN YOUR EVALUATION. THE INFORMATION YOU WILL
            PROVIDE WILL HELP THE UNIVERSITY SERVE YOU BETTER!!
          </p>
          <p style={{ textTransform: "uppercase", color: "crimson" }}>
            Click on a Registered Unit Listed on the Left Panel to Start!
          </p>
        </div>
      ) : semUnits?.loading ? (
        <div
          style={{
            marginTop: "20%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size={25} color="#04aa6d" />{" "}
          <Typography variant="subtitle2" style={{ color: "#0a8fc4" }}>
            fetching Units
          </Typography>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "50px",
          }}
        >
          <ErrorOutlined style={{ fontSize: 50 }} color="warning" />
          <Typography variant="h6" style={{ textAlign: "center" }}>
            You have not registered units for the current semester
          </Typography>
          {/* <Button
            variant="outlined"
            color="success"
            size={"medium"}
            endIcon={<ArrowForward />}
            onClick={() => {
              navigate("/internship-add");
            }}
          >
            Add New Internship
          </Button> */}
        </div>
      )}
    </>
  );
};

export default LecEvaluationGuidelines;
