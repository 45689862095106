import {
  FETCH_SEM_PERIOD_REQUEST,
  FETCH_SEM_PERIOD_SUCCESS,
  FETCH_SEM_PERIOD_FAILURE,
} from "./semPeriodTypes";

const initialState = {
  loading: false,
  semPeriods: null,
  currentSemPeriod: null,
  error: null,
};

const semPeriodReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEM_PERIOD_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_SEM_PERIOD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        semPeriods: action.payload.semPeriods,
        currentSemPeriod: action.payload.currentSemPeriod,
      };

    case FETCH_SEM_PERIOD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        semPeriods: [],
        currentSemPeriod: null,
      };
    default:
      return state;
  }
};

export default semPeriodReducer;
