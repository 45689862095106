import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Typography } from "@mui/material";
const CourseUnits = ({
  course_unit,
  handleSubmitUnit,
  evaluation_status,
  setCurrentSelectedUnit,
  currentSelectedUnit,
  openCard,
  setOpenCard,
}) => {
  return (
    <li
      className="sideitems"
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // flexWrap: "wrap",
        cursor: "pointer",
        // backgroundColor:
        //   currentSelectedUnit?.unitID == course_unit.unitID
        //     ? "#ddd"
        //     : "transparent",
        // borderLeft:
        //   currentSelectedUnit?.unitID == course_unit.unitID
        //     ? "3px solid #04aa6d"
        //     : null,

        boxSizing: "border-box",
      }}
      onClick={() =>
        // handleSubmitUnit(course_unit)
        {
          setCurrentSelectedUnit(course_unit);
          setOpenCard((state) => {
            return !state;
          });
        }
      }
    >
      {/* <button
        onClick={() =>
          // handleSubmitUnit(course_unit)
          setCurrentSelectedUnit(course_unit)
        }
      > */}
      <div>
        <Typography variant="subtitle2" style={{ fontSize: "14px" }}>
          <span style={{ color: "rgb(102, 101, 101)" }}>
            {course_unit.unitCode}
          </span>{" "}
          - {course_unit.unitName}
          {evaluation_status}
        </Typography>
      </div>
      {/* </button> */}
      <div>
        <ChevronRightIcon className="enter_arrow" />
      </div>
    </li>
  );
};

export default CourseUnits;
