import {
  FETCH_CURRENT_SEM_UNITS_REQUEST,
  FETCH_CURRENT_SEM_UNITS_SUCCESS,
  FETCH_CURRENT_SEM_UNITS_FAILURE,
} from "./semUnitsTypes";

const initialState = {
  loading: false,
  registeredUnits: [],
  error: null,
};

const semUnitsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_SEM_UNITS_REQUEST:
      return { ...state, loading: true, error: null };

    case FETCH_CURRENT_SEM_UNITS_SUCCESS:
      return {
        loading: false,
        error: null,
        registeredUnits: action.payload,
      };

    case FETCH_CURRENT_SEM_UNITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        registeredUnits: [],
      };
    default:
      return state;
  }
};

export default semUnitsReducer;
