import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Axios from "axios";
import {
  Field,
  FieldArray,
  Formik,
  useFormik,
  Form,
  ErrorMessage,
} from "formik";
import * as Yup from "yup";
import { Typography, Button, CircularProgress } from "@mui/material";
import {
  ErrorOutlined,
  ArrowForward,
  Edit,
  Lock,
  LockOpen,
  EditNote,
} from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import styles from "../../styles/sidenav.module.css";
import { basePath } from "../../util/basePath";
import ErrorText from "../../components/ErrorText";
const InternshipEdit = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feedBack, setFeedback] = useState(null);
  const [internshipDetails, setInternshipDetails] = useState();
  const [employeeDetails, setEmployeeDetails] = useState();
  const [lecturerList, setLecturerList] = useState(null);

  const url = basePath.url;
  const user = useSelector((state) => {
    return state.logger.user;
  });

  useEffect(() => {
    setLoading(true);
    setError(null);

    const getInternshipDetails = async (id) => {
      try {
        const internship = await axios.get(`${basePath.url}internship/${id}`);
        setInternshipDetails(internship.data.data);
        //console.log(internship.data.data);
        setLoading(false);
        setError(null);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };
    getInternshipDetails(location.state.internshipId);
  }, []);

  //   useEffect(() => {
  //     const getEmployeeDetails = async (id) => {
  //       try {
  //         const employee = await axios.get(
  //           `${basePath.url}users/get-by-user-id/${id}`
  //         );
  //         setEmployeeDetails(employee.data.data);
  //         console.log(employee.data.data);
  //         setLoading(false);
  //         setError(null);
  //       } catch (error) {
  //         setLoading(false);
  //         setError(error);
  //       }
  //     };
  //     getEmployeeDetails(internshipDetails[0].lecturer_assigned_id);
  //   }, [internshipDetails]);

  useEffect(() => {
    setLoading(true);
    setError(null);
    setFeedback(null);
    const empStatus = "all";
    const userStatus = "all";
    axios
      .get(`${url}users/get_users/${empStatus}/${userStatus}`)
      .then((res) => {
        let list = [];
        list = [...res.data.data];

        setLecturerList(list);

        setLoading(false);
        setError(null);
        setFeedback(null);
      })
      .catch((error) => {
        setLoading(false);
        setError("Error Loading Users");
        setFeedback(null);
      });
  }, []);

  const initialValues = {
    admission_number: user.admissionNo,
    start_date: internshipDetails && new Date(internshipDetails[0].start_date),
    end_date: internshipDetails && new Date(internshipDetails[0].end_date),
    payment_status: internshipDetails && internshipDetails[0].payment_status,
    assessment_status:
      internshipDetails && internshipDetails[0].assessment_status,
    progress_description:
      internshipDetails && internshipDetails[0].progress_description,
    organization: internshipDetails && internshipDetails[0].organization,
    county: internshipDetails && internshipDetails[0].county,
    country: internshipDetails && internshipDetails[0].country,
    town: internshipDetails && internshipDetails[0].town,
    physical_address:
      internshipDetails && internshipDetails[0].physical_address,
    industry_supervisor_name:
      internshipDetails && internshipDetails[0].industry_supervisor_name,
    industry_supervisor_position:
      internshipDetails && internshipDetails[0].industry_supervisor_position,
    industry_supervisor_email:
      internshipDetails && internshipDetails[0].industry_supervisor_email,
    industry_supervisor_phone:
      internshipDetails && internshipDetails[0].industry_supervisor_phone,
    tasks_assigned: internshipDetails && [],
    lec_user_id: internshipDetails && internshipDetails[0].lecturer_assigned_id,
  };

  const validationSchema = Yup.object({
    admission_number: Yup.string().required("Admission Number not set"),
    start_date: Yup.date().required("Please select a valid start date"),
    end_date: Yup.date().required("Please select a valid end date"),

    industry_supervisor_phone: Yup.string().required(
      "Please enter supervisor Phone Number"
    ),
    industry_supervisor_email: Yup.string().required(
      "Please enter supervisor email"
    ),
    industry_supervisor_position: Yup.string().required(
      "Please enter supervisor employment position"
    ),

    payment_status: Yup.string().required("Please select payment status"),
    assessment_status: Yup.string().required("Please select assessment status"),

    organization: Yup.string().required("Please enter organization name"),
    country: Yup.string().required("Country is required"),
    county: Yup.string().required("Please enter county or state"),
    physical_address: Yup.string().required("physical address is required"),
    industry_supervisor_name: Yup.string().required(
      "Enter your industry supervisor name"
    ),
  });

  const onSubmitInternship = async (values, onSubmitProps) => {
    //console.log(values);
    setLoading(true);
    setError(null);
    setFeedback(null);

    //console.log(`internshipId: ${location.state.internshipId}`);
    const internshipDetails = {
      id: location.state.internshipId,
      admission_number: values.admission_number,
      start_date: values.start_date,
      end_date: values.end_date,
      payment_status: values.payment_status,
      assessment_status: values.assessment_status,
      progress_description: values.progress_description,
      organization: values.organization,
      county: values.county,
      country: values.country,
      town: values.town,
      physical_address: values.physical_address,
      industry_supervisor_name: values.industry_supervisor_name,
      industry_supervisor_position: values.industry_supervisor_position,
      industry_supervisor_email: values.industry_supervisor_email,
      industry_supervisor_phone: values.industry_supervisor_phone,
      tasks_assigned: [],
      lec_user_id: values.lec_user_id,
    };

    try {
      const addInternship = await Axios.post(
        `${url}internship/add_update`,
        internshipDetails
      );

      setLoading(false);
      if (addInternship.data.message === "error") {
        setError(addInternship.data.data);
        setFeedback(null);
      } else {
        setError(null);
        setFeedback("Intership updated successfully!");
      }

      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    } catch (error) {
      setLoading(false);
      setError(error.message);
      setFeedback(null);
      onSubmitProps.setSubmitting(false);
    }
  };

  return (
    <>
      <div className={styles.topContent}>
        <Typography
          variant="h6"
          style={{
            fontWeight: 400,
            color: "#282727",
            padding: 10,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Internship</div>{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 10,
            }}
          >
            <EditNote style={{ fontSize: 20 }} color="info" />
            <Typography variant="subtitle2" style={{ fontSize: 14 }}>
              Edit
            </Typography>
          </div>
        </Typography>
      </div>

      <div>
        {error && (
          <div className="error_box">
            <Typography variant="subtitle2">{error}</Typography>
          </div>
        )}
      </div>
      <div>
        {feedBack && (
          <>
            <div className="success_box">
              <Typography variant="subtitle2">{feedBack}</Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                size="medium"
                color="success"
                onClick={() => {
                  return navigate("/internship");
                }}
                endIcon={<SendIcon />}
              >
                View Internships
              </Button>
            </div>
          </>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "centr",
          alignItems: "center",
          width: "100%",
        }}
      >
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 50,
            }}
          >
            Loading.... <CircularProgress color={"primary"} size={25} />
          </div>
        ) : null}
      </div>
      {internshipDetails && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitInternship}
          validationSchema={validationSchema}
        >
          {({ isValid, isSubmitting, values }) => {
            return (
              <Form>
                <div
                  className={styles.mainContent}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      className="search_box"
                      style={{ width: "auto", marginRight: 10 }}
                    >
                      <label>
                        Lecturer Assigned :
                        <span style={{ color: "skyblue" }}>
                          (ignore if not assigned)
                        </span>
                      </label>
                      <Field as="select" name="lec_user_id" id="lec_user_id">
                        <option value="">Select Lecturer Assigned</option>

                        {lecturerList
                          ? lecturerList
                              .filter((lec) => {
                                return (
                                  lec.user_group === "Lecturer" ||
                                  lec.user_group === "Hod" ||
                                  lec.user_group === "Dean"
                                );
                              })
                              .sort((a, b) => {
                                let fa = a.names.toLowerCase();
                                let fb = b.names.toLowerCase();

                                if (fa > fb) {
                                  return 1;
                                }
                                if (fa < fb) {
                                  return -1;
                                }
                                return 0;
                              })
                              .map((lecturer) => {
                                return (
                                  <option
                                    key={lecturer.user_id}
                                    value={lecturer.user_id}
                                  >
                                    {lecturer.names.toUpperCase()}
                                  </option>
                                );
                              })
                          : null}
                      </Field>
                      <ErrorMessage name="lec_user_id" component={ErrorText} />
                    </div>
                    <div
                      className="search_box"
                      style={{ width: "auto", marginRight: 10 }}
                    >
                      <label>Start Date :</label>
                      <Field
                        type="date"
                        id="start_date"
                        name="start_date"
                        style={{ padding: 3 }}
                      />
                      <ErrorMessage name="start_date" component={ErrorText} />
                    </div>
                    <div
                      className="search_box"
                      style={{ width: "auto", marginRight: 10 }}
                    >
                      <label>Expected Date of Completion </label>
                      <Field
                        type="date"
                        id="end_date"
                        name="end_date"
                        style={{ padding: 3 }}
                      />
                      <ErrorMessage name="end_date" component={ErrorText} />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      className="search_box"
                      style={{ width: "auto", marginRight: 10 }}
                    >
                      <label>Payment Status :</label>
                      <Field
                        as="select"
                        name="payment_status"
                        id="payment_status"
                      >
                        <option value="">Select Payment Status</option>
                        <option value="not paid">Not Paid</option>
                        <option value="paid">Paid</option>
                      </Field>
                      <ErrorMessage
                        name="payment_status"
                        component={ErrorText}
                      />
                    </div>
                    <div
                      className="search_box"
                      style={{ width: "auto", marginRight: 10 }}
                    >
                      <label>Assessment Status :</label>
                      <Field
                        as="select"
                        name="assessment_status"
                        id="assessment_status"
                      >
                        <option value="">Assessment Status</option>
                        <option value="pending">Pending</option>
                        <option value="assessed">Assessed</option>
                      </Field>
                      <ErrorMessage
                        name="assessment_status"
                        component={ErrorText}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.mainContent}>
                  <div className={styles.formSubContent}>
                    <div className="formInput">
                      <label>Organization:</label>
                      <Field
                        type="text"
                        id="organization"
                        name="organization"
                        placeholder="organization"
                      />
                      <ErrorMessage name="organization" component={ErrorText} />
                    </div>
                    <div className="formInput">
                      <label>Country: </label>
                      <Field
                        type="text"
                        id="country"
                        name="country"
                        placeholder="country"
                      />
                      <ErrorMessage name="country" component={ErrorText} />
                    </div>
                  </div>
                  <div className={styles.formSubContent}>
                    <div className="formInput">
                      <label>County: </label>
                      <Field
                        type="text"
                        id="county"
                        name="county"
                        placeholder="county"
                      />
                      <ErrorMessage name="county" component={ErrorText} />
                    </div>

                    <div className="formInput">
                      <label>Town: </label>
                      <Field
                        type="text"
                        id="town"
                        name="town"
                        placeholder="town"
                      />
                      <ErrorMessage name="town" component={ErrorText} />
                    </div>
                  </div>
                  <div className={styles.formSubContent}>
                    <div className="formInput">
                      <label>Physical Address: </label>
                      <Field
                        type="text"
                        id="physical_address"
                        name="physical_address"
                        placeholder="physical address"
                      />
                      <ErrorMessage
                        name="physical_address"
                        component={ErrorText}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={styles.mainContent}
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.formSubContent}>
                      <div className="formInput">
                        <label>Industry Supervisor Name:</label>
                        <Field
                          type="text"
                          id="industry_supervisor_name"
                          name="industry_supervisor_name"
                          placeholder="industry supervisor name"
                        />
                        <ErrorMessage
                          name="industry_supervisor_name"
                          component={ErrorText}
                        />
                      </div>
                      <div className="formInput">
                        <label>Industry Supervisor Position: </label>
                        <Field
                          type="text"
                          id="industry_supervisor_position"
                          name="industry_supervisor_position"
                          placeholder="industry supervisor position"
                        />
                        <ErrorMessage
                          name="industry_supervisor_position"
                          component={ErrorText}
                        />
                      </div>
                    </div>
                    <div className={styles.formSubContent}>
                      <div className="formInput">
                        <label>Industry Supervisor Email: </label>
                        <Field
                          type="text"
                          id="industry_supervisor_email"
                          name="industry_supervisor_email"
                          placeholder="industry supervisor email"
                        />
                        <ErrorMessage
                          name="industry_supervisor_email"
                          component={ErrorText}
                        />
                      </div>

                      <div className="formInput">
                        <label>Industry Supervisor Phone: </label>
                        <Field
                          type="text"
                          id="industry_supervisor_phone"
                          name="industry_supervisor_phone"
                          placeholder="industry supervisor phone"
                        />
                        <ErrorMessage
                          name="industry_supervisor_phone"
                          component={ErrorText}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginRight: 10,
                    }}
                  >
                    <div className="formInput">
                      <label>Attachment Progress Description:</label>
                      <Field
                        as="textarea"
                        id="progress_description"
                        name="progress_description"
                        placeholder="Please enter current status of your attachment progress"
                        style={{ fontSize: 12, minWidth: 300 }}
                      />
                      <ErrorMessage
                        name="progress_description"
                        component={ErrorText}
                      />
                    </div>
                  </div>
                </div>

                <Button
                  variant="contained"
                  type="submit"
                  endIcon={<SendIcon />}
                  style={{ margin: 10 }}
                  disabled={!isValid || isSubmitting}
                >
                  Submit
                </Button>
                <Button variant="outlined" type="reset">
                  Reset
                </Button>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default InternshipEdit;
