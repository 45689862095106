import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentSemUnits } from "../../redux/semUnits/semUnitsActions";
import { Digital, Dots, Levels, Spinner } from "react-activity";
import "react-activity/dist/Dots.css";
import "react-activity/dist/Spinner.css";
import LineSeparaor from "./line_separator";
import axios from "axios";
import Axios from "axios";
import { basePath } from "../../util/basePath";
import { Button, Typography } from "@mui/material";
import ProcessProgress from "../ProcessProgress";
const EvaluationForm = ({
  currentSelectedUnit,
  sem_period,
  admissionNo,
  setOpenCard,
}) => {
  const [isError, setError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isSubmiting_evaluation, setIsSubiting_evaluation] = useState(false);
  const [isLanding_text, setIsLanding_text] = useState(true);
  const [isLoading_lec, setIsLoading_lec] = useState(true);
  const [isUnit_evaluated, setIsUnit_evaluated] = useState(false);

  const [openProgress, setOpenProgress] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");
  const [progressTitle, setProgressTitle] = useState("");
  const [progressError, setProgressError] = useState(false);
  const [progressComplete, setProgressComplete] = useState(false);

  const [lec_id, setLec_id] = useState("");
  const [lec_name, setLec_name] = useState("");
  const [lec_unit_id, setLec_unit_id] = useState("");
  const [unit_type, setUnit_type] = useState("");
  const [cluster_id, setCluster_id] = useState("");
  const [course_unit_id, setCourseUnitID] = useState("");
  const [course_unit_name, setCourse_unit_name] = useState("");
  const [course_unit_code, setCourse_unit_code] = useState("");
  const [add_lec_input, setAdd_lec_input] = useState(false);
  const [lec_list, setLec_list] = useState([]);
  const [isPending_lec_list, setIsPending_lec_list] = useState(false);
  const [error_lec_list, setError_lec_list] = useState(null);

  const [attendance_punctuality, setAttendance_punctuality] = useState("");
  const [preparedness, setPreparedness] = useState("");
  const [relevant_material, setRelevant_material] = useState("");
  const [time_for_practices, setTime_for_practices] = useState("");
  const [relevant_answers, setRelevant_answers] = useState("");
  const [syllabus_coverage, setSyllabus_coverage] = useState("");
  const [clear_concepts, setClear_concepts] = useState("");
  const [student_relations, setStudent_relations] = useState("");
  const [assistance, setAssistance] = useState("");
  const [assignment_and_tests, setAssignment_and_tests] = useState("");

  const [teau_premises, setTeau_premises] = useState("");
  const [facilities, setFacilities] = useState("");
  const [it_equipment, setIt_equipment] = useState("");
  const [boarding_facilities, setBoarding_facilities] = useState("");
  const [lec_learning_resources, setLec_learning_resources] = useState("");
  const [sitting_cat, setSitting_cat] = useState("");
  const [any_other_comment, setAny_other_comment] = useState("");
  const [selectedCourseUnit, setSelectedCourseUnit] = useState({});

  const url = basePath.url;

  const dispatch = useDispatch();
  const logger = useSelector((state) => {
    return state.logger;
  });
  const semPeriod = useSelector((state) => {
    return state.semPeriodReducer;
  });
  const semPeriodId = useSelector((state) => {
    return state.semPeriodReducer?.currentSemPeriod?.periodID;
  });

  const clear_fields = () => {
    setError(false);
    // setProgressMessage("");
    // setProgressError(false);
    // setProgressComplete(false);
    //setRegisteredUnits([]);
    // setIsLoadingUnits(false);
    // setEvaluationUnit(true);
    // setIsUnitSet(false);
    setIsSubiting_evaluation(false);
    setIsLanding_text(true);
    setIsLoading_lec(true);

    // setLec_id("");
    // setLec_name("");
    // setLec_unit_id("");
    // setUnit_type("");
    // setCluster_id("");
    // setCourseUnitID("");
    // setCourse_unit_name("");
    // setCourse_unit_code("");
    setAdd_lec_input(false);

    setAttendance_punctuality("");
    setPreparedness("");
    setRelevant_material("");
    setTime_for_practices("");
    setRelevant_answers("");
    setSyllabus_coverage("");
    setClear_concepts("");
    setStudent_relations("");
    setAssistance("");
    setAssignment_and_tests("");

    setTeau_premises("");
    setFacilities("");
    setIt_equipment("");
    setBoarding_facilities("");
    setLec_learning_resources("");
    setSitting_cat("");
    setAny_other_comment("");
    //setSelectedCourseUnit({});
  };
  // useEffect(() => {
  //   console.log(currentSelectedUnit);
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setIsUnitSet(false);
    setIsSubiting_evaluation(true);
    setIsLanding_text(false);
    setProgressTitle("Lecturer Evaluation");
    setProgressMessage("Submitting Evaluation");
    setOpenProgress(true);

    if (
      attendance_punctuality &&
      preparedness &&
      relevant_material &&
      time_for_practices &&
      relevant_answers &&
      syllabus_coverage &&
      clear_concepts &&
      student_relations &&
      assistance &&
      assignment_and_tests &&
      teau_premises &&
      facilities &&
      it_equipment &&
      boarding_facilities &&
      lec_learning_resources &&
      sitting_cat &&
      lec_id
    ) {
      // console.log("iko fiti");
      // alert("niaje");
      // return;
      const data = {
        unitID: currentSelectedUnit.unitID,
        admission_no: admissionNo,
        sem_period: sem_period,
        attendance_punctuality: attendance_punctuality,
        preparedness: preparedness,
        relevant_material: relevant_material,
        time_for_practices: time_for_practices,
        relevant_answers: relevant_answers,
        syllabus_coverage: syllabus_coverage,
        clear_concepts: clear_concepts,
        student_relations: student_relations,
        assistance: assistance,
        assignment_and_tests: assignment_and_tests,
        teau_premises: teau_premises,
        facilities: facilities,
        it_equipment: it_equipment,
        boarding_facilities: boarding_facilities,
        lec_learning_resources: lec_learning_resources,
        sitting_cat: sitting_cat,
        any_other_comment: any_other_comment,
        course_unit_name: course_unit_name,
        course_unit_code: course_unit_code,
        // lec_name: currentSelectedUnit.names,
        lec_id: lec_id,
        // lec_unit_id: currentSelectedUnit?.lec_unit_id,
        unit_type: currentSelectedUnit?.unit_type,
        cluster_id: currentSelectedUnit?.cluster_id,
      };
      try {
        // console.log(`${url}lec-evaluation/submit-lec-unit-evaluation`);
        const result = await axios.post(
          `${url}lec-evaluation/submit-lec-unit-evaluation`,
          data,
          { headers: { "Content-Type": "application/json" } }
        );
        setProgressMessage("Course Unit Evaluation added successfuly!");
        setProgressComplete(true);
        // setOpenCard(false);

        clear_fields();
      } catch (error) {
        setProgressError(true);
        setProgressMessage(error.message);
      }
    } else {
      // setIsUnitSet(true);
      setProgressError(true);
      setProgressMessage("Please Fill all required fields to continue");
    }

    // setIsSubiting_evaluation(false);
  };

  // const handleSubmitUnit = (course_unit) => {
  //   setIsLoading_lec(true);
  //   setSelectedCourseUnit(course_unit);

  //   setCourseUnitID(course_unit.unitID);
  //   setCourse_unit_name(course_unit.unitName);
  //   setCourse_unit_code(course_unit.unitCode);
  //   console.log(course_unit);
  //   //setAdd_lec_input(false);
  //   fetch(`${url}lec-evaluation/lec-unit-details`, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       unitID: course_unit.unitID,
  //       sem_period: sem_period,
  //     }),
  //   })
  //     .then((res) => res.json())
  //     .then((responseData) => {
  //       // setReturned_lec_unit_details(responseData);

  //       // console.log(responseData);
  //       if (responseData.length == 0) {
  //         setLec_name("");
  //         setLec_id("");
  //         setLec_unit_id("");
  //         setUnit_type("");
  //         setCluster_id("");
  //         setIsLoading_lec(false);
  //         setAdd_lec_input(true);
  //         setAdd_lec_input(true);
  //         setIsUnitSet(true);
  //         setLec_list([]);

  //         fetch(`${url}lec-evaluation/get-lecturers`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         })
  //           .then((res) => {
  //             if (!res.ok) {
  //               throw Error("Could not fetch data");
  //             } else {
  //               return res.json();
  //             }
  //             //console.log(res);
  //           })
  //           .then((data) => {
  //             setLec_list(data);
  //             setIsPending_lec_list(false);
  //             setError_lec_list(null);
  //             //console.log(lec_list);
  //           })
  //           .catch((err) => {
  //             setIsPending_lec_list(false);
  //             setError_lec_list(err.message);
  //           });
  //       } else {
  //         responseData.map((item) => {
  //           //console.log(item);
  //           setLec_name(item.names);
  //           setLec_id(item.empID);
  //           setLec_unit_id(item.lec_unit_id);
  //           setUnit_type(item.student_type_id);
  //           setCluster_id(item.cluster_id);
  //           setAdd_lec_input(false);

  //           get_evaluation_status(course_unit);

  //           setIsUnitSet(true);
  //           setIsLanding_text(false);
  //           setIsLoading_lec(false);
  //           setAdd_lec_input(false);
  //         });
  //       }

  //       //console.log(lec_name, lec_id, lec_unit_id);
  //       //console.log(returned_lec_unit_details_object);
  //       //setSelectedCourseUnit({...data });
  //       //  setIsUnitSet(true);

  //       // setSelectedCourseUnit(course_unit);
  //       // setIsUnitSet(true);
  //     })
  //     .catch((err) => {
  //       setError(true);
  //       setErrMsg(err);
  //       setIsUnitSet(false);
  //       console.log(err);
  //     });

  //   //fetch emp id, cluster id, lec_unit_id

  //   //console.log(selectedCourseUnit);
  // };
  Axios.defaults.withCredentials = true;

  const loadLecList = async () => {
    setIsPending_lec_list(true);
    setLec_list([]);
    setError_lec_list(null);

    try {
      const list = await axios.get(
        `${url}users/get_users/all/all?user_group=Lecturer`
      );
      setLec_list(list.data.data);
      setIsPending_lec_list(false);
      setError_lec_list(null);
    } catch (error) {
      setError_lec_list(error.message);
      setIsPending_lec_list(false);
      setLec_list([]);
    }
  };

  useEffect(() => {
    loadLecList();
  }, []);

  useEffect(() => {
    if (currentSelectedUnit.empID) {
      clear_fields();
      setLec_id(currentSelectedUnit.empID);
    } else {
      clear_fields();
    }
  }, [currentSelectedUnit]);
  const handleClose = () => {
    setOpenProgress(false);
    setOpenCard(false);
    dispatch(fetchCurrentSemUnits(logger?.user?.admissionNo, semPeriodId));
  };
  return (
    <>
      <ProcessProgress
        openProgress={openProgress}
        setOpenProgress={setOpenProgress}
        progressMessage={progressMessage}
        progressTitle={progressTitle}
        progressError={progressError}
        progressComplete={progressComplete}
        handleCallBack={handleClose}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "stretch",
          paddingTop: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            fontWeight: 600,
            flexWrap: "wrap",
            gap: 30,
          }}
        >
          <div>
            <Typography
              variant="subtitle2"
              style={{ fontSize: 16, fontWeight: 500 }}
            >
              <strong> Unit Name:</strong> {currentSelectedUnit.unitName}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subtitle2"
              style={{ fontSize: 16, fontWeight: 500 }}
            >
              <strong> Unit Code:</strong> {currentSelectedUnit.unitCode}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            fontWeight: 600,
          }}
        >
          <div>
            {isPending_lec_list ? (
              <div
                style={{
                  marginTop: "20%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner size={15} color="#04aa6d" />
              </div>
            ) : null}

            <div>
              <Typography
                variant="subtitle2"
                style={{ fontSize: 16, fontWeight: 500 }}
              >
                <strong>Lecturer:</strong> {currentSelectedUnit?.names}
              </Typography>
              {""}
              {!currentSelectedUnit?.names ? (
                <select
                  type="text"
                  name="lec_id"
                  value={lec_id}
                  onChange={(e) => setLec_id(e.target.value)}
                  style={{ padding: 5, fontSize: 16 }}
                >
                  <option value="">Select Lecturer</option>
                  {isPending_lec_list ? (
                    <div
                      style={{
                        marginTop: "20%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner size={15} color="#04aa6d" />
                    </div>
                  ) : (
                    lec_list.map((lecturer, index) => (
                      <option key={index} value={lecturer.empID}>
                        {lecturer.names}
                      </option>
                    ))
                  )}
                </select>
              ) : // <p style={{ color: "crimson" }}>
              //   Sorry! This Unit has not been set by your Department. Please
              //   Consult your HOD for assistance
              // </p>
              null}
            </div>
          </div>
        </div>
        {currentSelectedUnit.isEvaluated ? (
          <div
            style={{
              width: "99%",
              marginTop: 20,
              marginLeft: 0,
              marginRight: 0,
              padding: 15,
              backgroundColor: "#def5ed",
              boxSizing: "border-box",
              borderLeft: "3px solid green",
            }}
          >
            Unit Already Evaluated!
          </div>
        ) : (
          <form>
            {/* <p>Lec ID: {lec_id}</p> */}
            <div
              style={{
                width: "100%",
                border: "1px solid #ccc",
                overflow: "hidden",
                marginTop: "20px",
                boxSizing: "border-box",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <div className="row_container">
                <div className="col-60" style={{ width: "60%" }}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    SUBJECT OF EVALUATION
                  </Typography>
                </div>
                <div className="col-40">
                  <div style={{ width: 10 }}>
                    <strong>SD</strong>
                  </div>
                  <div style={{ width: 10 }}>
                    <strong>D</strong>
                  </div>
                  <div style={{ width: 10 }}>
                    <strong>N</strong>
                  </div>
                  <div style={{ width: 10 }}>
                    <strong>A</strong>
                  </div>
                  <div style={{ width: 10 }}>
                    <strong>SA</strong>
                  </div>
                  <div style={{ width: 10 }}>
                    <strong>NA</strong>
                  </div>
                </div>
              </div>
              <LineSeparaor />
              <div className="row_container">
                <div className="col-60" style={{ width: "60%" }}>
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>1. </strong>Lecturers attendance and punctuality
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="attendance"
                      // value= {1}
                      // onchange={(e) => setAttendance_punctuality(e.target.value)}

                      onChange={() => setAttendance_punctuality("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="attendance"
                      onChange={() => setAttendance_punctuality("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="attendance"
                      onChange={() => setAttendance_punctuality("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="attendance"
                      onChange={() => setAttendance_punctuality("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="attendance"
                      onChange={() => setAttendance_punctuality("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="attendance"
                      onChange={() => setAttendance_punctuality("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>2. </strong>
                      Does the lecturer come prepared for class?
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="preparedness"
                      onChange={() => setPreparedness("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="preparedness"
                      onChange={() => setPreparedness("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="preparedness"
                      onChange={() => setPreparedness("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="preparedness"
                      onChange={() => setPreparedness("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="preparedness"
                      onChange={() => setPreparedness("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="preparedness"
                      onChange={() => setPreparedness("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>3. </strong>
                      Does the lecturer provide relevant material?
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_material"
                      onChange={() => setRelevant_material("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_material"
                      onChange={() => setRelevant_material("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_material"
                      onChange={() => setRelevant_material("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_material"
                      onChange={() => setRelevant_material("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_material"
                      onChange={() => setRelevant_material("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_material"
                      onChange={() => setRelevant_material("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>4. </strong>
                      Are you given sufficient time to do practices on your own?
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="time_for_practices"
                      onChange={() => setTime_for_practices("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="time_for_practices"
                      onChange={() => setTime_for_practices("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="time_for_practices"
                      onChange={() => setTime_for_practices("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="time_for_practices"
                      onChange={() => setTime_for_practices("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="time_for_practices"
                      onChange={() => setTime_for_practices("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="time_for_practices"
                      onChange={() => setTime_for_practices("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>5. </strong>
                      Does the lecturer give relevant answers to your questions?
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_answers"
                      onChange={() => setRelevant_answers("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_answers"
                      onChange={() => setRelevant_answers("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_answers"
                      onChange={() => setRelevant_answers("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_answers"
                      onChange={() => setRelevant_answers("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_answers"
                      onChange={() => setRelevant_answers("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="relevant_answers"
                      onChange={() => setRelevant_answers("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>6. </strong>
                      Is the syllabus coverage appropriate?
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="syllabus_coverage"
                      onChange={() => setSyllabus_coverage("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="syllabus_coverage"
                      onChange={() => setSyllabus_coverage("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="syllabus_coverage"
                      onChange={() => setSyllabus_coverage("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="syllabus_coverage"
                      onChange={() => setSyllabus_coverage("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="syllabus_coverage"
                      onChange={() => setSyllabus_coverage("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="syllabus_coverage"
                      onChange={() => setSyllabus_coverage("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>7. </strong>
                      Subject concepts are clearly explained
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="clear_concepts"
                      onChange={() => setClear_concepts("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="clear_concepts"
                      onChange={() => setClear_concepts("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="clear_concepts"
                      onChange={() => setClear_concepts("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="clear_concepts"
                      onChange={() => setClear_concepts("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="clear_concepts"
                      onChange={() => setClear_concepts("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="clear_concepts"
                      onChange={() => setClear_concepts("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>8. </strong>
                      The lecturer relates well with the students
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="student_relations"
                      onChange={() => setStudent_relations("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="student_relations"
                      onChange={() => setStudent_relations("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="student_relations"
                      onChange={() => setStudent_relations("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="student_relations"
                      onChange={() => setStudent_relations("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="student_relations"
                      onChange={() => setStudent_relations("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="student_relations"
                      onChange={() => setStudent_relations("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>9. </strong>I am well assisted when I have a
                      problem in class
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assistance"
                      onChange={() => setAssistance("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assistance"
                      onChange={() => setAssistance("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assistance"
                      onChange={() => setAssistance("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assistance"
                      onChange={() => setAssistance("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assistance"
                      onChange={() => setAssistance("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assistance"
                      onChange={() => setAssistance("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>10. </strong>
                      Quality and frequency of assignments and tests.
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assignment_and_tests"
                      onChange={() => setAssignment_and_tests("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assignment_and_tests"
                      onChange={() => setAssignment_and_tests("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assignment_and_tests"
                      onChange={() => setAssignment_and_tests("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assignment_and_tests"
                      onChange={() => setAssignment_and_tests("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assignment_and_tests"
                      onChange={() => setAssignment_and_tests("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="assignment_and_tests"
                      onChange={() => setAssignment_and_tests("6")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                border: "1px solid #ccc",
                overflow: "hidden",
                marginTop: "20px",
                boxSizing: "border-box",
                padding: "10px",
                borderRadius: "4px",
              }}
            >
              <div className="row_container">
                <div className="col-100">
                  <h2
                    className="header_title"
                    style={{ borderBottom: "1px solid #ccc" }}
                  >
                    SECTION II: ADMINISTRATION AND FACILITIES
                  </h2>
                </div>
              </div>
              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>1. </strong>
                      TEAU Premises are conducive and comfortable for study
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="teau_premises"
                      onChange={() => setTeau_premises("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="teau_premises"
                      onChange={() => setTeau_premises("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="teau_premises"
                      onChange={() => setTeau_premises("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="teau_premises"
                      onChange={() => setTeau_premises("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="teau_premises"
                      onChange={() => setTeau_premises("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="teau_premises"
                      onChange={() => setTeau_premises("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>2. </strong>
                      Teaching, training and accommodation facilities are
                      adequate
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="facilities"
                      onChange={() => setFacilities("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="facilities"
                      onChange={() => setFacilities("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="facilities"
                      onChange={() => setFacilities("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="facilities"
                      onChange={() => setFacilities("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="facilities"
                      onChange={() => setFacilities("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="facilities"
                      onChange={() => setFacilities("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>3. </strong>
                      The Universities IT equipment is well maintained
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="it_equipment"
                      onChange={() => setIt_equipment("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="it_equipment"
                      onChange={() => setIt_equipment("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="it_equipment"
                      onChange={() => setIt_equipment("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="it_equipment"
                      onChange={() => setIt_equipment("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="it_equipment"
                      onChange={() => setIt_equipment("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="it_equipment"
                      onChange={() => setIt_equipment("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>4. </strong>
                      The boarding facilities are comfortable and sufficient.
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="boarding_facilities"
                      onChange={() => setBoarding_facilities("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="boarding_facilities"
                      onChange={() => setBoarding_facilities("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="boarding_facilities"
                      onChange={() => setBoarding_facilities("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="boarding_facilities"
                      onChange={() => setBoarding_facilities("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="boarding_facilities"
                      onChange={() => setBoarding_facilities("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="boarding_facilities"
                      onChange={() => setBoarding_facilities("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>5. </strong>
                      The lecturer learning resources in time
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="lec_learning_resources"
                      onChange={() => setLec_learning_resources("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="lec_learning_resources"
                      onChange={() => setLec_learning_resources("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="lec_learning_resources"
                      onChange={() => setLec_learning_resources("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="lec_learning_resources"
                      onChange={() => setLec_learning_resources("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="lec_learning_resources"
                      onChange={() => setLec_learning_resources("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="lec_learning_resources"
                      onChange={() => setLec_learning_resources("6")}
                    />
                  </div>
                </div>
              </div>

              <LineSeparaor />

              <div className="row_container">
                <div className="col-60">
                  <label>
                    <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                      <strong>6. </strong>
                      There was at least one sit-in CAT
                    </Typography>
                  </label>
                </div>
                <div className="col-40">
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="sitting_cat"
                      onChange={() => setSitting_cat("1")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="sitting_cat"
                      onChange={() => setSitting_cat("2")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="sitting_cat"
                      onChange={() => setSitting_cat("3")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="sitting_cat"
                      onChange={() => setSitting_cat("4")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="sitting_cat"
                      onChange={() => setSitting_cat("5")}
                    />
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="radio"
                      name="sitting_cat"
                      onChange={() => setSitting_cat("6")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row_container" style={{ marginTop: 20 }}>
              <div className="col-100">
                <p>Please give any other comment: </p>
              </div>
              <div className="col-100">
                <textarea
                  name="any_other_comment"
                  placeholder="Any Other Comment"
                  value={any_other_comment}
                  onChange={(e) => setAny_other_comment(e.target.value)}
                  //   style={{ height:'200px',width:'100%',borderRadius:'4px'}}
                ></textarea>
              </div>
            </div>

            <div className="row_container">
              <div className="col-100" style={{ minHeight: 80 }}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="success"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default EvaluationForm;
